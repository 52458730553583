import React from "react";
// import { MDBBtn, MDBTable, MDBTableBody, MDBTableHead  } from 'mdbreact';
import { MDBTable, MDBTableBody, MDBTableHead,MDBBtn, MDBModalFooter } from "mdbreact";
import "../index.css";

const Rewiews = props => {
  
  const columns = [
    
    {
      label: "Name",
      field: "itemName",
      sort: "asc"
    },
    {
      label: "Price",
      field: "price",
      sort: "asc"
    },
    {
      label: "Quantity",
      field: "quantity",
      sort: "asc"
    },
    {
      label: "",
      field: "remove",
      sort: "asc"
    }
  ];

  const rows_regular_btn = [
    {
      
      itemName: "Product1-Shop1",
      price: "Rs.30",
      quantity: (
        <select
          id="selectlocation"
          className="browser-default custom-select  form-control-sm"
        >
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
        </select>
      ),
      remove: (
        <a href="#!">
          <i className="far fa-times-circle orange-text-dark3"></i>
        </a>
      )
    },
    {
      
      itemName: "Product2-Shop1",
      price: "Rs40",
      quantity: (
        <select
          id="selectlocation"
          className="browser-default custom-select form-control"
        >
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
        </select>
      ),
      remove: (
        <a href="#!">
          <i className="far fa-times-circle orange-text-dark3"></i>
        </a>
      )
    },
    {
      
      itemName: "Product1-Shop2",
      price: "Rs200",
      quantity: (
        <select
          id="selectlocation"
          className="browser-default custom-select form-control"
        >
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
        </select>
      ),
      remove: (
        <a href="#!">
          <i className="far fa-times-circle orange-text-dark3"></i>
        </a>
      )
    },
    {
      
      itemName: "Product1-Shop2",
      price: "Rs.5000",
      quantity: (
        <select
          id="selectlocation"
          className="browser-default custom-select form-control"
        >
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
        </select>
      ),
      remove: (
        <a href="#!">
          <i className="far fa-times-circle orange-text-dark3"></i>
        </a>
      )
    },
    {
     
      itemName: "Product2-Shop2",
      price: "Rs.400",
      quantity: (
        <select
          id="selectlocation"
          className="browser-default custom-select form-control"
        >
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
        </select>
      ),
      remove: (
        <a href="#!">
          <i className="far fa-times-circle orange-text-dark3"></i>
        </a>
      )
    }
    
  ];
  const CartTotal=500;
  return (
    
    <>
      
      <MDBTable responsiveSm className="searchshoptable" btn id="searchshop">
        {<MDBTableHead columns={columns} />}
        <MDBTableBody rows={rows_regular_btn} />
      </MDBTable>
      <MDBModalFooter>
    <table width="100%">
      <tr>
        <td align='left'>
        <p className="mb-3 h4 orange-text-dark3">
                      <strong><i class="fas fa-rupee-sign"></i>. {CartTotal}</strong>
                    </p>
        
        </td>
        <td align='right'>
        <MDBBtn
              color="primary"
              className="btn btn-primary btn-md orange darken-3"
              href="/cartCheckOutOrder"
            >
              Checkout <i class="fas fa-angle-double-right"></i>
            </MDBBtn>
       
        </td>
      </tr>
    </table>


     
         </MDBModalFooter>
            <div>               
              Your Cart is empty.
                <MDBBtn
                  color="primary"
                  className="btn btn-primary btn-sm orange darken-3"
                  href="/products"
                >
                  Shop now
                </MDBBtn>
              </div>
    </>
  );
};

export default Rewiews;
