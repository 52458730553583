import React, { Component } from "react";
import {
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBEdgeHeader,
  MDBFreeBird,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBCardBody,
  MDBAlert
} from "mdbreact";
import Manageusers from "./Manageusers";
import ManageShop from "./ManageShop";
import ManageProductAdmin from "./ManageProductAdmin";
import  { fetchService } from '../utilities/GlobalFunctions';
import SectionContainer from "../components/sectionContainer";
import "../index.css";
import { Redirect } from "react-router-dom";

class Adminproduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
    activeItem: "1",
    cssClass: "activetab",
    getdropdowndetails: [],
    isLoading: true,
    CodeID: 0,
    CodeValue: '',
    CodeDescription: '',
    Data1: '',
    CreatedBy: '',
    UpdatedBy: '',
    cssSuccessMessage:'hide',
    IsValidAdmin: (this.props.location.state && this.props.location.state.data !== undefined) ?this.props.location.state.data:""
    }
    // this.setState({IsValidAdmin: IsValidAdmin});
    this.Adminproduct.addProduct = this.Adminproduct.addProduct.bind(this);
    this.Adminproduct.handleChange = this.Adminproduct.handleChange.bind(this);
  };

  Adminproduct = {

    addProduct: async (event) => {
      event.preventDefault();
      const data = {
        CodeID: parseInt(this.state.CodeID),
        CodeValue: this.state.CodeValue,
        CodeDescription: this.state.CodeDescription,
        Data1: this.state.Data1,
        CreatedBy: localStorage.getItem('lsLoginName'),
        UpdatedBy: localStorage.getItem('lsLoginName')
      }
      const output_save = await fetchService('CodeValue', 'SaveCodeValueData', 'POST', data);
      console.log(output_save);
      if (output_save ===200) {
        this.setState({cssSuccessMessage:'show'
        ,CodeValue:''
      , CodeDescription:''
      ,Data1:''      
      });
        
      }
      console.log(output_save);
    },
    handleChange: (e) => {
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  }
  toggle = tab => e => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
        cssClass: "activetab"
      });
    }
  };
 async componentDidMount() {
  // const validadmin = fetchService('Admin', 'IsValidAdmin', 'POST',localStorage.getItem('lsLoginName'));
//  this.setState({IsValidAdmin: validadmin});
    const GetCodeIDDD = await fetchService('ShopLocation', 'GetCodeID', 'POST');
    if(GetCodeIDDD !== null){
      this.setState({
        getdropdowndetails: GetCodeIDDD,
        isLoading: false 
      });
    
  }   
    console.log(this.state.getdropdowndetails)
    console.log(this.state.IsValidAdmin);
  }

  render() {
    if (localStorage.getItem('lsLoginName')!== null && this.state.IsValidAdmin!=="") {
      return (
        <>
          <MDBEdgeHeader color="orange lighten-1" className="sectionPage" />
          <div className="mt-3 mb-5">
            <MDBFreeBird>
              <MDBRow className="orderpageheight">
                <MDBCol
                  md="12"
                  className="mx-auto float-none white z-depth-1 py-2 px-2"
                >
                  <MDBCardBody className="text-center">
                    <h4 className="h3-responsive mb-3 orange-text-dark3">
                      <strong>EshopE, Welcome {localStorage.getItem('lsUserName')}</strong>
                    </h4>
                    <hr className="my-1" />
                    <MDBNav className="nav-tabs mt-2">
                      <MDBNavItem>
                        <MDBNavLink
                          to="#"
                          active={this.state.activeItem === "1"}
                          onClick={this.toggle("1")}
                          role="tab"
                          className={
                            this.state.activeItem === "1" && this.state.cssClass
                          }
                        >
                          Add Product Caetogory
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem>
                        <MDBNavLink
                          to="#"
                          active={this.state.activeItem === "2"}
                          onClick={this.toggle("2")}
                          role="tab"
                          className={
                            this.state.activeItem === "2" && this.state.cssClass
                          }
                        >
                          Manage User
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem>
                        <MDBNavLink
                          to="#"
                          active={this.state.activeItem === "3"}
                          onClick={this.toggle("3")}
                          role="tab"
                          className={
                            this.state.activeItem === "3" && this.state.cssClass
                          }
                        >
                          Manage Shop
                        </MDBNavLink>
                      </MDBNavItem>
                    </MDBNav>
                    <MDBTabContent activeItem={this.state.activeItem}>
                      <MDBTabPane tabId="1" role="tabpanel">
                      <MDBRow>
            <MDBCol className="mx-auto">
              <div id="productlist" className="p-2">
                <h5 className="text-center">Add Code Value</h5>
                <MDBAlert color="success" className={this.state.cssSuccessMessage}>
                  Added Successfully!
                </MDBAlert>
                <SectionContainer>
                <form onSubmit={this.Adminproduct.addProduct}>
                          <ManageProductAdmin value={this.state.getdropdowndetails} handleChange={this.Adminproduct.handleChange} />
                          </form>
                </SectionContainer>
              </div>
            </MDBCol>
          </MDBRow>      
                      </MDBTabPane>
                      <MDBTabPane tabId="2" role="tabpanel">
                        <Manageusers />
                      </MDBTabPane>
                      <MDBTabPane tabId="3" role="tabpanel">
                        <ManageShop />
                      </MDBTabPane>
                    </MDBTabContent>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBFreeBird>

            <MDBContainer>
              <MDBRow>
                <MDBCol md="12" className="mt-4">
                  <p className="text-center text-muted mb-1">
                    Only Authorised to Administrator of EshopE
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </>
      );
    }
else{
  return <Redirect to="/"></Redirect>
}
  }
}
export default Adminproduct;
