import React from "react";
import { MDBContainer, MDBBtn, MDBModalFooter } from "mdbreact";
import "../index.css";

const ProductDetails = (props) => {
  localStorage.setItem("lspidpage", localStorage.getItem("lspid"));
  localStorage.removeItem("lspid");
  const CartTotal = 500;

  return (
    <>
      <MDBContainer>
        <div className="row wow fadeIn">
          <div class="col-md-6 mb-2 mt-1">
            <img
              src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Products/14.jpg"
              className="img-fluid"
              alt=""
            ></img>
          </div>

          <div class="col-md-6 mb-4">
            <div class="p-4">
              {localStorage.getItem("lspid")}
              <div class="mb-3">
                <a href="#!">
                  <span className="badge orange mr-1">Category 2 </span>
                </a>

                <a href="#!">
                  <span className="badge grey mr-1">Bestseller</span>
                </a>
                <a href="#!">
                  <span className="badge grey mr-1">
                    Product id: {localStorage.getItem("lspidpage")}
                  </span>
                </a>
              </div>

              <p class="lead">
                <span>
                  <i class="fas fa-rupee-sign"></i>. {CartTotal}
                </span>
              </p>

              <p className="lead font-weight-bold orange-text-dark3">
                Description
              </p>

              <p className="p-2">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Et
                dolor suscipit libero eos atque quia ipsa sint voluptatibus!
                Beatae sit assumenda asperiores iure at maxime atque repellendus
                maiores quia sapiente.
              </p>

              <form className="d-flex justify-content-left">
                <input
                  type="text"
                  className="form-control"
                  id="inputquantity"
                  defaultValue="1"
                  placeholder="Quantity"
                  name="productquantity"
                ></input>
              </form>
            </div>
          </div>
        </div>
      </MDBContainer>
      <MDBModalFooter>
        <table width="100%">
          <tr>
            <td align="left">
              <p className="mb-3 h4 orange-text-dark3">
                <strong>
                  <i class="fas fa-rupee-sign"></i>. {CartTotal}
                </strong>
              </p>
            </td>
            <td align="right">
              <MDBBtn
                color="primary"
                className="btn btn-primary btn-md orange darken-3"
                href="/cartCheckOutOrder"
              >
                Add to Cart <i class="fas fa-cart-plus"></i>
              </MDBBtn>
            </td>
          </tr>
        </table>
      </MDBModalFooter>
    </>
  );
};

export default ProductDetails;
