import React, {useState, useEffect } from "react";
import { MDBIcon, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { fetchService } from "../utilities/GlobalFunctions";
import {Link} from "react-router-dom";

const UserMenu = (props) => {
    const[dynamicMenu,setdynamicMenu] = useState([]);
    const[isLoading,setisLoading] = useState(true);

    useEffect(() => {
        const fetchDynamicMenu = async () => {
            setisLoading(true);
            if (localStorage.getItem('lsLoginName') !== null) {
                const res = await fetchService('ShopLocation', 'GetMenu', 'POST', localStorage.getItem('lsLoginName'));
                if (res !== "" && res !== null) {
                    setdynamicMenu(res);
                    setisLoading(false);
                    console.log(res);
                    return res;
                }
                else {
                    setdynamicMenu([]);
                }
            }

        };
        fetchDynamicMenu();
    }, []);
    return (
        <>

            <MDBDropdown className={props.class}>
                <MDBDropdownToggle nav caret >
                    <span className="white-text">
                          <MDBIcon icon="user" />
                    </span>
                </MDBDropdownToggle>

                <MDBDropdownMenu>
                    {/* <MDBDropdownItem >
                        <a className="dropdown-item" href="#!" >{'Welcome  ' + localStorage.getItem('lsUserName')} </a>
                    </MDBDropdownItem> */}
                    {isLoading === false &&  dynamicMenu != null && dynamicMenu.map(link =>
                        <MDBDropdownItem key={link.menuName}>
                            {link.menuDescription === 'Logout' &&  <a className="dropdown-item" href={link.menuLink} onClick={props.togglefunction(link.menuDescription)}>{link.menuDescription}</a> }
                            {link.menuDescription !== 'Logout' &&  <Link className="dropdown-item" to={{pathname: link.menuLink,state: { data: link.roleName, shopID: props.shopID }}}
                              >{link.menuDescription}</Link>}
                            {/* <a className="dropdown-item" href={link.menuLink} onClick={props.togglefunction(link.menuDescription)}>{link.menuDescription}</a> */}
                        </MDBDropdownItem>
                    )}
                </MDBDropdownMenu>
            </MDBDropdown>
        </>
    )
}



// class UserMenu extends Component {

//     constructor(props) {
//         super(props);
//         console.log(props);
//         this.state = {
//             dynamicMenu: [],
//             isLoading: true
//         }
//     }

//     async componentDidMount() {
//         const output = await fetchService('ShopLocation', 'GetMenu', 'POST', localStorage.getItem('lsUserName')) ;
//         if (output !== "E" & output !== "UNIQUEKEY") {
//             this.setState({
//                 isLoading: false,
//                 dynamicMenu: output
//             });
//         }
//          console.log(this.state.dynamicMenu);
//        }

//     render() {
//         return (
//             <>

//                 <MDBDropdown className={this.props.class}>
//                     <MDBDropdownToggle nav caret >
//                         <span className="white-text">
//                               <MDBIcon icon="user" />
//                         </span>
//                     </MDBDropdownToggle>

//                     <MDBDropdownMenu>
//                         {/* <MDBDropdownItem >
//                             <a className="dropdown-item" href="#!" >{'Welcome  ' + localStorage.getItem('lsUserName')} </a>
//                         </MDBDropdownItem> */}
//                         {this.state.isLoading === false && this.state.dynamicMenu != null && this.state.dynamicMenu.map(link =>
//                             <MDBDropdownItem key={link.menuName}>
//                                  <Link to={{pathname: link.menuLink,state: { data: this.state.dynamicMenu }}}
//                                   onClick={this.props.togglefunction(link.menuDescription)}>{link.menuDescription}</Link>
//                                 {/* <a className="dropdown-item" href={link.menuLink} onClick={this.props.togglefunction(link.menuDescription)}>{link.menuDescription}</a> */}
//                             </MDBDropdownItem>
//                         )}
//                     </MDBDropdownMenu>
//                 </MDBDropdown>


                

//             </>
//         )
//     }
// }

export default UserMenu;