import React, { Component } from "react";
import { 
  MDBDataTable,
  MDBBtn,
  MDBIcon,

} from "mdbreact";
import { fetchService } from "../utilities/GlobalFunctions";
class ManageProductAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LoadGridUsers: [],
      dynamicMenu: null,
      isLoading: true
    };
  }

  async componentDidMount() {
    const output = await fetchService('CodeValue', 'GetAllCategories', 'POST', localStorage.getItem('lsLoginName'));
    if (output !=null ) {
     this.setState({
       isLoading: false,
       LoadGridUsers: output 
     }); 
    }
     console.log(this.state.LoadGridUsers);
   }
  render() {

    const griddata = this.state.LoadGridUsers;
    const data = {
      columns: [
        {
          label: "Manage",
          field: "handle",
          sort: "asc",
          width: 100
        },
        {
          label: "Category",
          field: "categoryDescription",
          sort: "asc",
          width: 200
        },

        {
          label: "Code ID",
          field: "codeID",
          sort: "asc",
          width: 200
        },

        {
          label: "Description",
          field: "codeDescription",
          sort: "asc",
          width: 100
        },
        {
          label: "Code Value",
          field: "codeValue",
          sort: "asc",
          width: 100
        }
      ],
      rows: this.state.isLoading===false && griddata.map(obj=> ({'handle':<MDBBtn className="btn-sm orange darken-3 white-text" >
         <MDBIcon icon="angle-double-right" /></MDBBtn>,
         "CategoryDescription":obj.lobjmodCode.codeDescription
         ,"CodeID":obj.codeID
         ,"CodeDescription":obj.codeDescription,"CodeValue":obj.codeValue}))
    };
        
    return (
      <>        

                  <div>
                    <div className="form-row">
                      <div className="form-group col-md-3">
                        <label htmlFor="lblusername">
                          Code ID <span className="required">*</span>
                        </label>
                        <select
                          type="text"
                          className="form-control"
                          id="CodeID"
                          name="CodeID"
                          onChange={this.props.handleChange}
                          required
                        >
                          <option value="">--select--</option>
                          {this.props.value != null && this.props.value.map(
                            details => <option value={details.codeID}>{details.codeDescription}</option>)}
                        </select>
                      </div>
                      <div className="form-group col-md-3">
                        <label htmlFor="lblpwd">
                          Code Value <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="CodeValue"
                          placeholder="Code Value"
                          name="CodeValue"
                          onChange={this.props.handleChange}
                          required
                          maxLength= "4"
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label htmlFor="lblcnfpwd">
                          Description <span className="required">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="CodeDescription"
                          name="CodeDescription"
                          placeholder="Description"
                          onChange={this.props.handleChange}
                          required
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label htmlFor="lblcnfpwd">
                          Comments <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="Data1"
                          name="Data1"
                          placeholder="Comments"
                          onChange={this.props.handleChange}
                          required
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-md orange darken-3" 
                      >
                        Add Product Category
                        </button>
                    </div>
                    <div className="overflow-x">
                  <MDBDataTable sortable striped bordered small data={data} />
                </div>
                    <hr color="orange"></hr>
                  </div>
       
      </>
    );
  }
}

export default ManageProductAdmin;
