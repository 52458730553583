import React from "react";
import { fetchService } from "../utilities/GlobalFunctions";
import {
  MDBEdgeHeader,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBJumbotron,
  MDBIcon,
  MDBAnimation,
  MDBBtn
} from "mdbreact";
const config = require("../config");
class ContactPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      recipient: '',
      sender: config.eshope_Contactpage_fromEmail,
      subject: '',
      text: '',
      name: '',
      success:false,
      isLoading:true
    }
    this.postEmailData = this.postEmailData.bind(this);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  postEmailData = async () => {
    const data = {
      recipient: this.state.recipient,
      sender: this.state.sender,
      subject: this.state.subject,
      text: this.state.text,
      name: this.state.name 
    }
    console.log(data);
    const output_save = await fetchService('Email', 'SaveEmail', 'POST', data);
    console.log(output_save);
  }
  render() {

  return (
    <>
      <MDBEdgeHeader color="orange lighten-1" className="sectionPage" />
      <MDBAnimation type="zoomIn" duration="500ms">
        <MDBContainer>
          <MDBRow>
            <MDBCol md="10" className="mx-auto">
              <MDBJumbotron className="mt-3">
                <h2 className="text-center">
                  <span className="orange-text small">
                    <MDBIcon icon="envelope" />
                  </span>
                  Write to us
                </h2>
                <MDBContainer>
                  <MDBRow>
                    <MDBCol md="12">
                      <form  onSubmit={this.postEmailData}>
                        <label
                          htmlFor="defaultFormContactNameEx"
                          className="grey-text"
                        >
                          Your Email
                        </label>
                        <input
                          type="email"
                          id="defaultFormContactNameEx"
                          className="form-control"
                          name="recipient"
                          onChange={(e) => this.handleChange(e)} />
                        
                        
                        <br />
                        <label
                          htmlFor="defaultFormContactEmailEx"
                          className="grey-text"
                        >
                          Your Name
                        </label>
                        <input
                          type="text"
                          id="defaultFormContactEmailEx"
                          className="form-control"
                          name="name"
                          onChange={(e) => this.handleChange(e)}/>
                      
                        
                        <br />
                        <label
                          htmlFor="defaultFormContactSubjectEx"
                          className="grey-text"
                        >
                          Subject
                        </label>
                        <input
                          type="text"
                          id="defaultFormContactSubjectEx"
                          className="form-control"
                          name="subject"
                          onChange={(e) => this.handleChange(e)}/>
                      
                        
                        <br />
                        <label
                          htmlFor="defaultFormContactMessageEx"
                          className="grey-text"
                        >
                          Your message
                        </label>
                        <textarea
                          type="text"
                          id="defaultFormContactMessageEx"
                          className="form-control"
                          rows="3"
                          name="text"
                          onChange={(e) => this.handleChange(e)}/>
                      
                        
                        <div className="text-center mt-4">
                          <MDBBtn color="warning" outline type="submit">
                            Send
                            <MDBIcon far icon="paper-plane" className="ml-2" />
                          </MDBBtn>
                        </div>
                      </form>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBJumbotron>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBAnimation>
    </>
  );
};}

export default ContactPage;



