import React, { Component } from "react";
import {
  MDBEdgeHeader,
  MDBFreeBird,
  MDBModalFooter,
  MDBBtn,
  MDBCol,
  MDBRow,
  MDBCardBody,
  MDBCloseIcon
 
} from "mdbreact";
import { MDBDataTable } from "mdbreact";
import "../index.css";
import Spinner, { fetchService } from "../utilities/GlobalFunctions";
import NumberedTextbox from "../utilities/NumberedTextbox";
import { Link, Redirect } from "react-router-dom";
class CartTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LoadCartDetails: [],
      isLoading: true,
      checkout: [],
      cartcount: "",
      loggedinUsername:this.props.location && this.props.location.state!==undefined && this.props.location.state !==null 
      && this.props.location.state.loggedinUsername,
      userRole: this.props.location && this.props.location.state!==undefined && this.props.location.state !==null 
      && this.props.location.state.userRole,
    };
    console.log(this.state.loggedinUsername)
  }

  async componentDidMount() {
    this.setState.isLoading = true;
    const output = await fetchService(
      "CartDetails",
      "GetCartDetailsForCustomer",
      "POST",
      localStorage.getItem("lsLoginName")
    );

    if (output !== null) {
      this.setState({
        isLoading: false,
        LoadCartDetails: output,
        cartcount: output.length,
      });
    }
  }

  RemoveCartItems= async(cartID,productID) =>{
    this.setState.isLoading = true;
    const data ={
      CartID : cartID,
      ProductID : productID,
      LoginName : localStorage.getItem('lsLoginName')
    }
    const output = await fetchService(
      "CartDetails",
      "RemoveCartDetails",
      "POST",
      data
    );

    if (output !== 500 && output !==204 && output!==400) {
      const output_cart =  await fetchService(
        "CartDetails",
        "GetCartDetailsForCustomer",
        "POST",
        localStorage.getItem("lsLoginName")
      );
      if(output_cart !== 500 && output_cart !==204 && output_cart!==400){
        this.setState({
          isLoading: false,
          LoadCartDetails: output_cart,
          cartcount: output_cart.length,
        });
      }
    }
  }

  render() {
    const griddata = this.state.LoadCartDetails;
    const isLoading = this.state.isLoading;
    const data = {
      columns: [
        {
          label: "Name",
          field: "productName",
          sort: "asc",
          
        },
        {
          label: "Price",
          field: "price",
          sort: "asc",
     
        },
        {
          label: "Quantity",
          field: "numbers",
          sort: "asc",
          minimal: "sm",
          align:'center',
          editable:true
        },
        {
          label: "Total",
          field: "totalPrice",
          sort: "asc",
        
        },
        {
          label: "",
          field: "remove",
       
          
        },
      ],
      rows:
        this.state.isLoading === false &&
        griddata.map((obj) => ({
          // 'handle':<MDBBtn className="btn-sm orange darken-3 white-text" >
          //  <MDBIcon icon="angle-double-right" /></MDBBtn>,
          productName: obj.productName,
          price: obj.price,
          numbers: (
            <NumberedTextbox input={obj.numberofItems} productid ={obj.productID} ></NumberedTextbox>
          ),
          totalPrice: obj.totalPrice,
          remove: (<i className="far fa-times-circle orange-text-dark3" style={{cursor:"pointer"}} onClick={(e)=>this.RemoveCartItems(obj.cartID,obj.productID)}></i>
            
          ),
        })),
    };
    const cartrowcount = this.state.cartcount;
    if (isLoading) {
      return <Spinner> Loading ...</Spinner>;
    } else if (
      this.state.loggedinUsername!=='' &&
     this.state.userRole === 'USER'
    ) {
      if (cartrowcount > 0) {
        return (
          <>
            <MDBEdgeHeader color="orange lighten-1" className="sectionPage" />
            <div className="mt-3 mb-5">
              <MDBFreeBird>
                <MDBRow className="orderpageheight">
                  <MDBCol
                    md="12"
                    className="mx-auto float-none white z-depth-1 py-2 px-2"
                  >
                    <MDBCardBody className="text-center">
                      <p className="mb-3 orange-text-dark3">
                        <strong>
                          {/* Welcome {localStorage.getItem("lsUserName")} */}
                          Your Cart
                        </strong>
                      </p>
                      
                      <hr className="my-1" />

                      <div className="minheight450">
                        <div >
                          <MDBDataTable id="carttable"
                            sortable
                            striped
                            bordered
                            small
                            
                            searching={false}
                            data={data}
                           
                          />
                        </div>
                        <MDBModalFooter>
                          <table width="100%">
                            <tr>
                              <td align="left">
                                <p className="mb-3 h4 orange-text-dark3">
                                  {/* <strong><i class="fas fa-rupee-sign"></i>. {CartTotal}</strong> */}
                                </p>
                              </td>
                              <td align="right">
                                <Link
                                  color="primary"
                                  className="btn btn-primary btn-md orange darken-3"
                                  to={{
                                    pathname: "/CheckOut",
                                    state: { checkout: griddata,loggedinUsername:this.props.location.state.loggedinUsername
                                      ,userRole:this.props.location.state.userRole },
                                  }}
                                >
                                  Checkout{" "}
                                  <i class="fas fa-angle-double-right"></i>
                                </Link>
                              </td>
                            </tr>
                          </table>
                        </MDBModalFooter>
                      </div>
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </MDBFreeBird>
            </div>
          </>
        );
      } else {
        return (
          <>
            <MDBEdgeHeader color="orange lighten-1" className="sectionPage" />
            <div className="mt-3 mb-5">
              <MDBFreeBird>
                <MDBRow className="orderpageheight minheight450">
                  <MDBCol
                    md="12"
                    className="mx-auto float-none white z-depth-1 py-2 px-2"
                  >
                    <MDBCardBody className="text-center">
                      <div className="">
                        Your Cart is empty.
                        <hr />
                        <Link to={{ pathname: '/products', state: { loggedinUsername: this.state.loggedinUsername, userRole: this.state.userRole } }}
                        >
                          {" "}
                          <i class="fas fa-3x fa-store orange-text darken-3 handcursor"></i>
                        </Link>
                        <br />
                        <Link to={{ pathname: '/products', state: { loggedinUsername: this.state.loggedinUsername, userRole: this.state.userRole } }}
                        >

                          <MDBBtn
                            color="primary"
                            className="btn btn-primary btn-sm orange darken-3"
                          >
                            Shop now
                        </MDBBtn>
                        </Link>
                      </div>{" "}
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </MDBFreeBird>
            </div>
          </>
        );
      }
    } else {
      return <Redirect to="/login"></Redirect>;
    }
  }
}

export default CartTable;
