import React from "react";
import {
  MDBEdgeHeader,
  MDBFreeBird,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBCardBody,
  MDBIcon,
  MDBBtn
  
} from "mdbreact";
import Slider from '../components/Slider'
import {Link} from "react-router-dom";
import "../HomePage.css";

//import RegisterShopNavPage from "./RegisterShopNavPage";
import { fetchService } from "../utilities/GlobalFunctions";
import Homesection from "../components/Homesection";

class HomePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: "",
     imageUrl:"",
     isLoading: true,
     loggedinUsername:this.props.location && this.props.location.state!==undefined && this.props.location.state !==null 
     && this.props.location.state.loggedinUsername,
     userRole: this.props.location && this.props.location.state!==undefined && this.props.location.state !==null 
     && this.props.location.state.userRole,
    };
    // this.ManageProduct.postData = this.ManageProduct.postData.bind(this);
    // this.ManageProduct.handleChange = this.ManageProduct.handleChange.bind(
    //   this
    // );
  }
   
  async componentDidMount() {       
    const output = await fetchService('Image', 'GetBackgroundImage', 'POST');
    if (output !== "E" && output !== "UNIQUEKEY") {
      this.setState({
        isLoading: false,
        imageUrl: output
      });
    }
    // console.log(this.state.imageUrl);
    if (this.state.userRole ==='USER' ) {
      const count = await fetchService('ShopLocation', 'GetCartCount', 'POST', localStorage.getItem('lsLoginName'));
      if (output !== "401" && output !== "400") {
        localStorage.setItem('lscount', count);
      }
    }
  }
  
  scrollToTop = () => window.scrollTo(0, 0);
  render() {
    return (
      <>
      <div className="dynamic-background" style={{backgroundImage:"url("+ (this.state.isLoading === false && this.state.imageUrl && this.state.imageUrl.map(obj=> obj.imageUrl))+")"}}></div>
        <MDBEdgeHeader color="orange lighten-1" className="sectionPage" />
        <div className="mt-3 mb-5">
          <MDBFreeBird>
            <MDBRow>
              <MDBCol
                md="10"
                className="mx-auto float-none white z-depth-1 py-2 px-2 zindex"
              >
                <MDBCardBody className="text-center">
                  <h2 className="h2-responsive mb-4 orange-text-dark3">
                    <strong className="font-weight-bold">
                    
                      Welcome to EshopE
                    </strong>
                  </h2>
                  <MDBRow />
                  <p>Your are in the place to get what you need from your nearby shop</p>
    
                  <Slider/>
                  <p/>
                  <p>
                    Feel the product you are buying, buy prodcut directly from the owner. There is no intermediate. Its U and Seller.
                  </p>
                  <Homesection/>
                  <MDBRow className="d-flex flex-row justify-content-center row">
                    <Link to={{ pathname: '/products', state: { data: "", loggedinUsername: this.state.loggedinUsername, userRole: this.state.userRole } }}
                    >
                      <MDBBtn color='Orange' className="orange darken-2 white-text" >
                        <MDBIcon icon="shopping-bag" className="px-2" />
                    Begin your Live Shopping here
                  </MDBBtn>
                    </Link>
                  </MDBRow>
                
                 
                  <MDBRow className="d-flex flex-row justify-content-center row">
                    
                    <MDBBtn color='Grey' className="grey darken-2 white-text" href="/registershop">
                    <MDBIcon icon="warehouse" className="px-2" />
                   
                    Register your Shop with us today
                  </MDBBtn>
                
                  </MDBRow>
                  <p className="mt-4 orange-text"><a href='/ShopOwnerHome'>Shop Owner Home Page(Testing)</a></p>
               
                 
                <h3 className="text-center my-3 font-weight-bold orange-text-dark3">
                  Why we are so great?
                </h3>
                <h5 className="text-center my-3 font-weight-bold orange-text-dark3">
                  Our aim to bring all small scale shop in one place.
                </h5>
                <p className="text-center text-muted mb-1">
                  New shops and shops near to you are added daily.
                </p>
                <p className="text-center text-muted mb-1">
                  Do you want to sell you product to your nearby areas, please register here and start selling 
                </p>
                <p className="text-center my-2 orange-text-dark3">
                  Select product . Order . Pay to seller direclty . Verify the product . Pick up
                  <br/>
                  We save your wait time. 
                </p>
               

                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </MDBFreeBird>
        
          <MDBContainer>
       </MDBContainer>
          
        </div>
        
      </>
    );
  }
}

export default HomePage;
