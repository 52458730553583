import React, { Component } from "react";
import {
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBRow,
  MDBCol,
  // MDBModal,
  // MDBModalBody,
  // MDBModalHeader,
  // MDBModalFooter
} from "mdbreact";
import { fetchService } from "../utilities/GlobalFunctions";
//import { Link } from "react-router-dom";

class Manageusers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LoadGridUsers: [],
      isLoading: true
    };
  }
 
  async componentDidMount() {
   const output = await fetchService('UserRegistration', 'GetUsers', 'POST', localStorage.getItem('lsLoginName'));
   
   if (output != null ) {
    this.setState({
      isLoading: false,
      LoadGridUsers: output 
    }); 
   }
    console.log(this.state.LoadGridUsers);
  }
  
  render() {
    const griddata = this.state.LoadGridUsers;
    const data = {
      columns: [
        {
          label: "Manage",
          field: "handle",
          sort: "asc",
          width: 100
        },
        {
          label: "Name",
          field: "fullName",
          sort: "asc",
          width: 200
        },

        {
          label: "Mobile",
          field: "userMobileNo",
          sort: "asc",
          width: 200
        },

        {
          label: "Email",
          field: "userEmail",
          sort: "asc",
          width: 100
        },
        {
          label: "Role",
          field: "istrRoleName",
          sort: "asc",
          width: 100
        },
        {
          label: "Status",
          field: "userStatus",
          sort: "asc",
          width: 100
        }
      ],
      rows: this.state.isLoading === false && griddata.map(obj => ({
        'handle': <MDBBtn className="btn-sm orange darken-3 white-text" >
          <MDBIcon icon="angle-double-right" /></MDBBtn>, "FullName": obj.fullName, "userMobileNo": obj.userMobileNo
        , "userEmail": obj.userEmail, "istrRoleName": obj.istrRoleName, "UserStatus": obj.userStatus
      }))
    };

    return (
      <>
        
          <MDBRow>
            <MDBCol className="mx-auto">
              <div id="productlist" className="p-2">
                <h5 className="text-center">Manage User</h5>
                <MDBBtn
                  className="btn btn-primary btn-md orange darken-3"
                  href='./registershop'
                >
                  Add New User
                </MDBBtn>
                <div className="overflow-x">
                  <MDBDataTable striped bordered small data={data}/>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        
     
      </>
    );
  }
}

export default Manageusers;
