import React from "react";
import {
  MDBEdgeHeader,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBJumbotron,
  MDBIcon,
  MDBAlert
} from "mdbreact";
//import MenuLink from "./../components/menuLink";
import SectionContainer from "./../components/sectionContainer";
import UserRegister from "./UserRegister";
import { fetchService } from "../utilities/GlobalFunctions";

//import { Redirect } from "react-router-dom";
//import { async } from "q";

class RegisterShopPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      OwnerUserName: '',
      OwnerPassword: '',
      OwnerFirstName: '',
      OwnerMiddleName: '',
      OwnerLastName: '',
      OwnerDateofbirth: '',
      OwnerEmail: '',
      OwnerMobileNo: '',
      OwnerPhoneNo: '',
      shopphoneno: '',
      shopName: '',
      shopType: '',
      shopCodeValue: '',
      shopAddress: '',
      shopwebsiteName: '',
      shopcity: '',
      shopstate: '',
      shoppinCode: '',
      shopdescription: '',
      getCodeValue: [],
      isLoading: false,
      cssClass: "hide",
      ShopRegMsg: "",
      ShopRegMsgColor: ""
    }
    this.postData.postData = this.postData.postData.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  async componentDidMount() {
    this.setState({
      getCodeValue: await fetchService('ShopLocation', 'GetCodeValue', 'POST','ShopType'),
      isLoading: false

    });
    // console.log(this.state.getCodeValue)
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  postData = {
    postData: async event => {
      event.preventDefault();
      // postData = async () => {
      this.setState({
        isLoading: true
      })
      const data = {
        // user
        OwnerUserName: this.state.userUserName,
        OwnerPassword: this.state.userPassword,
        OwnerFirstName: this.state.userFirstName,
        OwnerMiddleName: this.state.userMiddleName,
        OwnerLastName: this.state.userLastName,
        OwnerDateofbirth: this.state.userDateofbirth,
        OwnerEmail: this.state.userEmail,
        OwnerMobileNo: this.state.userMobileNo,
        OwnerPhoneNo: this.state.userPhoneNo,
        //shop
        shopphoneno: this.state.shopphoneno,
        shopName: this.state.shopName,
        shopType: this.state.shopType,
        shopAddress: this.state.shopAddress,
        shopwebsiteName: '',
        shopcity: this.state.shopcity,
        shopstate: this.state.shopstate,
        shoppinCode: this.state.shoppinCode,
        shopdescription: this.state.shopdescription

      }

      const output_save = await fetchService('UserRegistration', 'SaveShopData', 'POST', data);


      if (output_save ===200) {

        this.setState({

          isLoading: false,
          cssClass: "show",
          ShopRegMsg: "Your Shop has been sucessfully registered. Please wait for 24 hour to activate your account",
          ShopRegMsgColor: "success"

        });
        setTimeout(() => window.location.reload(), 5000);

      } 
      else if (output_save === 208) {
        this.setState({
          isLoading: false,
          cssClass: "show",
          ShopRegMsg: "Username/Mobile no./EmailId is already registered. Please contact us or use different details to register",
          ShopRegMsgColor: "danger"
        });
      }
      
      
      else {
        this.setState({
          isLoading: false,
          cssClass: "show",
          ShopRegMsg: "Please enter correct details and register",
          ShopRegMsgColor: "danger"
        });
        //setTimeout(() => window.location.reload(), 1000);

      }


      console.log(output_save);

    }
  }
  render() {
    const message = this.state.ShopRegMsg;
    return (
      <>
        <MDBEdgeHeader color="orange lighten-1" className="sectionPage" />

        <MDBContainer>
          <MDBRow>
            <MDBCol md="10" className="mx-auto">
              <MDBJumbotron className="mt-3">
                <h3 className="text-center">
                  <span className="orange-text small px-2">
                    <MDBIcon icon="envelope" />
                  </span>
                  Register your Shop
                </h3>
                <p className="text-center">Start Selling your product</p>

                <MDBContainer>
                  <SectionContainer>
                    <MDBAlert
                      color={this.state.ShopRegMsgColor}
                      className={this.state.cssClass}
                    >
                      {message}
                    </MDBAlert>
                    <form id="shopregister" onSubmit={this.postData.postData}>
                      <h5 className="orange-text">Shop Owner Details </h5>
                      <hr color="orange" ></hr>
                      <UserRegister handleChange={this.handleChange} />
                      <h5 className="orange-text">Shop Details </h5>
                      <hr color="orange" ></hr>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="inputshopname">Shop Name <span className="required">*</span> </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputshopname"
                            placeholder="Shopname"
                            name="shopName"
                            onChange={(e) => this.handleChange(e)}
                            required
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="inputPassword4">Shop Type <span className="required">*</span></label>
                          <select
                            type="text"
                            className="form-control"
                            id="inputshoptype"
                            placeholder="State"
                            name="shopType"
                            onChange={(e) => this.handleChange(e)}
                            required
                          >
                            <option value="">--select--</option>
                            {!this.state.isLoading && this.state.getCodeValue != null && this.state.getCodeValue.map(
                              details => <option value={details.codeValue}>{details.codeDescription}</option>)}
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputshopAddress">Shop Address <span className="required">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputshopAddress"
                          placeholder="Building no, Street Name, Floor, Town/Village"
                          onChange={(e) => this.handleChange(e)}
                          name="shopAddress"
                          required
                        />
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-4">
                          <label htmlFor="inputCity">City <span className="required">*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputshopCity"
                            placeholder="Trichy"
                            onChange={(e) => this.handleChange(e)}
                            name="shopcity"
                            required
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="inputstate">State <span className="required">*</span></label>
                          <select
                            type="text"
                            className="form-control"
                            id="inputshopstate"
                            placeholder="State"
                            onChange={(e) => this.handleChange(e)}
                            name="shopstate"
                            required
                          >
                            <option value="Select"></option>
                            <option value="Trichy">Trichy</option>
                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="inputpincode">Pincode <span className="required">*</span> </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputshoppincode"
                            placeholder="600123"
                            onChange={(e) => this.handleChange(e)}
                            name="shoppinCode"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="inputmobileno">Mobile No. <span className="required">*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputshopmobileno"
                            placeholder="9876543210"
                            onChange={(e) => this.handleChange(e)}
                            name="shopphoneno"
                            required
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="inputphoneno">Phone No.</label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputshopphoneno"
                            placeholder="0000-000000"
                            onChange={(e) => this.handleChange(e)}
                            name="phoneno"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputshopdescription">
                          Shop Description
                        </label>
                        <textarea
                          type="multi "
                          className="form-control"
                          id="inputshopdescription"
                          placeholder="Describe about your shop"
                          onChange={(e) => this.handleChange(e)}
                          name="shopdescription"
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-md orange darken-3"

                      >
                           {" "}
                      {this.state.isLoading ? "Registering..." : " Register your Shop"}{" "}
                   
                      </button>
                    </form>
                  </SectionContainer>
                </MDBContainer>
              </MDBJumbotron>
            </MDBCol>
          </MDBRow>
        </MDBContainer>

      </>
    )
  };
}
export default RegisterShopPage;
