module.exports={
    port:8085,
    secret:'',
    awsconfig:{
        //aws profile user eshope
        secretAccessKey:'myotLZwuoH9Tr6YAJOidRRBQzW5qSxGK1++oO+e6',
        accessKeyId:'AKIA52CURCVVMGRDAAOI',
        region:'us-east-2'
    },
Productbucketname:'eshopedb',
SENDGRID_API_KEY:'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',
eshope_Contactpage_fromEmail:'noreply@eshope.com',
EamilGreeting:'<p>Thanks for contacting us. We will get back to you soon in 24-48 hours.</p> <br/>----<br/>'
}