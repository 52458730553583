import React ,  { Component }from "react";
import {
  MDBEdgeHeader,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBView,
  MDBIcon,
  MDBAnimation,
  MDBCardBody,
  //MDBBtn,
  MDBMask,
  MDBJumbotron
} from "mdbreact";

import "../index.css";

class ShopOwnerHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseID: "",
      ModalLogin: false,
      Modal15: false,
      Modal16: false}
    }
  
  render() {
  
  return (
    <>
      <MDBEdgeHeader color="orange lighten-1" className="sectionPage" />
      <MDBAnimation type="zoomIn" duration="500ms">
        <MDBContainer>
          <MDBJumbotron className="mt-3">
            <h3 className="text-center">
              <span className="orange-text small">
                <MDBIcon icon="users" />
              </span>
              Welcome, Shop Name
            </h3>
            <p className="p-4">Hello, Shop Name. Welcome to EshopE, your are in right place to enhance your business. Here you can manage your products
          , view your selling history.
            </p>
            <section className="text-center">
              <MDBRow className="text-center p-3">
                <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
                  <MDBView className="overlay rounded z-depth-1" waves>
                    <img
                      src="https://mdbootstrap.com/img/Photos/Others/images/58.jpg"
                      alt=""
                      className="img-fluid"
                    />
                    <a href="#!">
                      <MDBMask overlay="white-slight" />
                    </a>
                  </MDBView>
                  <MDBCardBody className="pb-0">
                    <h4 className="font-weight-bold my-3">Manage Orders</h4>
                    <p className="grey-text">
                      View your customer order details here
                    </p>
                 
                  </MDBCardBody>
                </MDBCol>
                <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
                  <MDBView className="overlay rounded z-depth-1" waves>
                    <img
                      src="https://mdbootstrap.com/img/Photos/Others/project4.jpg"
                      alt=""
                      className="img-fluid"
                    />
                    <a href="#!">
                      <MDBMask overlay="white-slight" />
                    </a>
                  </MDBView>
                  <MDBCardBody className="pb-0">
                    <h4 className="font-weight-bold my-3">Manage your Shop Prducts</h4>
                    <p className="grey-text">
                     View, Delete, Update your shop products
                    </p>
                    
                  </MDBCardBody>
                </MDBCol>
                <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
                  <MDBView className="overlay rounded z-depth-1" waves>
                    <img
                      src="https://mdbootstrap.com/img/Photos/Others/images/88.jpg"
                      alt=""
                      className="img-fluid"
                    />
                    <a href="#!">
                      <MDBMask overlay="white-slight" />
                    </a>
                  </MDBView>
                  <MDBCardBody className="pb-0">
                    <h4 className="font-weight-bold my-3">Selling History</h4>
                    <p className="grey-text">
                     View your selling history
                    </p>
                 
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </section>{" "}
          </MDBJumbotron>
        </MDBContainer>
      </MDBAnimation>
    </>
  );
};}

export default ShopOwnerHome;
