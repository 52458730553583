import React, { Component } from "react";
import Spinner from "../utilities/GlobalFunctions";
import "../index.css";
import {  MDBRow, MDBCol } from "mdbreact";
import { fetchService } from "../utilities/GlobalFunctions";
import {Redirect,Link} from "react-router-dom";

class Posts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: this.props.posts,
      loading: this.props.loading,
    };
  }

  Posts = {
    saveToCart: async (productID) => {
      // const productID = e.target.value;
      // event.preventDetault();
      const data = {
        ProductID: parseInt(productID),
        NumberofItems: parseInt("1"),
        LoginName: localStorage.getItem("lsLoginName"),
      };
      console.log(this.props.loggedinUsername,this.props.userRole);
      if (this.props.loggedinUsername!=='' && this.props.userRole==='USER') {
        const output = await fetchService(
          "CartDetails",
          "SaveCartDetails",
          "POST",
          data
        );
        if (output != null) {
          localStorage.setItem("lscount", output);
          setTimeout(() => window.location.reload(), 100);
        }
      }
      else{
        // setTimeout(() => window.location.reload(), 100);
        alert('Please Login to add items to cart');
      }
    },
    handleChange: (e) => {
      this.setState({
        [e.target.name]: e.target.value,
      });
    },
  };
  renderRedirect = (e) => {
  
    localStorage.setItem("lspid", e);
    console.log(localStorage.getItem("lspid"));
    window.location.reload(false)
    };
  render() {
    //const Posts = ({ posts, loading }) => {
      
    if (this.props.loading) {
      return <Spinner> Loading ... Please Wait...</Spinner>;
    }
    // if(this.props.loggedinUsername==='' || this.props.userRole===''){
    //   return <Redirect to="/login"></Redirect>;
    // }

    let divclear = {
      clear: "Both",
    };
if(localStorage.getItem("lspid")==null)
    return (
      <>
        <MDBRow className="mytextcenter productheader">
          <MDBCol md="12">
            <div className="active-orange mb-4">
              <input
                className="form-control"
                type="text"
                placeholder="Search Product"
                aria-label="Search"
              />
            </div>
          </MDBCol>
        </MDBRow>
        <div class="row mt-3">
          {this.props.posts.map((post, i) => {
            return (
              <div id="products" class="col-md-3" key={i}>
                <figure class="card card-product">
                  <div class="img-wrap">
                    {/* <a class="btn-overlay pt-2" href="/productdetails">
                      <i class="fa fa-search-plus"></i> 
                    </a> */}
                    <img class="rounded-circle" alt="100x100"
                      src={post.imageUrl}
                    />
                  </div>

                  <figcaption class="info-wrap">
                    <span onClick={(e) => this.renderRedirect(post.productID)} >
                      <div class="s-5 text-dots orange-text prodcutnamelink handcursor">
                        {post.productName}
                      </div>
                    </span>
                    <div class="action-wrap">
                      {/* <MDBBtn value={post.productID}> */}
                      <div class="input-group mb-1">
                        <div class="input-group-prepend">
                          <span class="input-group-text cartprice">
                            <i class="fas fa-rupee-sign fa-sm"> </i>
                            {post.price}
                          </span>
                        </div>
                        <div class="input-group-append">
                          <span class="input-group-text cartprice" id="basic-addon2">
                            {" "}
                            <div>
                              <i
                                class="fas fa-cart-plus fa-lg orange-text handcursor"
                                onClick={(e) =>
                                  this.Posts.saveToCart(post.productID)
                                }
                              ></i>
                            </div>{" "}
                          </span>
                        </div>
                      </div>
                      {/* <div><i class="fas fa-cart-plus fa-lg orange-text handcursor" onClick={(e) => this.Posts.saveToCart(post.productID)}></i></div>  */}

                      {/* </MDBBtn> 
       <div class="price-wrap h6">
                       <del className="grey-text">
                          <small>${post.price}</small>
                        </del> 
                        <span class="price-new">${post.price}</span>
                      </div>*/}
                    </div>
                  </figcaption>
                </figure>
              </div>
            );
          })}
        </div>

        <div style={divclear}></div>
      </>
    );
    else
    {
      return <Redirect to={`/productdetails/${localStorage.getItem("lspid")}`}></Redirect>
    }
  }
}
export default Posts;
