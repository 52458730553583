import React from "react";
import {} from "mdbreact";

import "../index.css";

class UserRegister extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }
  render() {
    return (
      <>

        <div>
          <div className="form-row">
            <div className="form-group col-md-4">
              <label htmlFor="lblfirstname">First Name <span className="required">*</span></label>
              <input type="text" className="form-control" id="inputfirstname" placeholder="First Name" name="userFirstName" onChange={this.props.handleChange} 
              required
              />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="lblmiddlename">Middle Name</label>
              <input type="text" className="form-control" id="inputmiddlename" placeholder="Middle Name" name="userMiddleName" onChange={this.props.handleChange} />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="lbllastname">Last Name <span className="required">*</span> </label>
              <input type="text" className="form-control" id="inputlastname" placeholder="Last Name" name="userLastName" onChange={this.props.handleChange} 
              required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="inputDOB">Date of Birth <span className="required">*</span> </label>
              <input type="date" className="form-control" id="inputDOB" placeholder="DD/MM/YYYY" name="userDateofbirth" onChange={this.props.handleChange} 
              required/>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="inputemail">Email  <span className="required">*</span> </label>
              <input type="email" className="form-control" id="inputZip" placeholder="xyz@xyx.com" name="userEmail" onChange={this.props.handleChange} 
              required
              />
            </div>
          </div>
          <div className="form-group hide" >
            <label htmlFor="inputAddress">Address <span className="required">*</span></label>
            <input type="text" className="form-control" id="inputuserAddress" placeholder="Building no, Street Name, Floor, Town/Village"
              name="userAddress" onChange={this.props.handleChange} />
          </div>
          <div className="form-row hide">
            <div className="form-group col-md-4">
              <label htmlFor="inputCity">City</label>
              <input type="text" className="form-control" id="inputuserCity" placeholder="Trichy" name="userCity" onChange={this.props.handleChange}
              />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="inputstate">State <span className="required">*</span></label>
              <select type="text" className="form-control" id="inputuserstate" placeholder="State" name="userState" onChange={this.props.handleChange} >
                <option value="1">Select</option>
                <option value="2">Trichy</option>
              </select>
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="inputpincode">Pincode <span className="required">*</span></label>
              <input type="text" className="form-control" id="inputuserpincode" placeholder="600123" name="userPinCode" onChange={this.props.handleChange}
               />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="inputmobileno">Mobile No. <span className="required">*</span></label>
              <input type="text" className="form-control" id="inputmobileno" placeholder="9876543210" name="userMobileNo" onChange={this.props.handleChange}
              required />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="inputphoneno">Phone No.</label>
              <input type="text" className="form-control" id="inputphoneno" placeholder="0000-000000" name="userPhoneNo" onChange={this.props.handleChange}/>
            </div>
          </div>
          <hr color="orange"></hr>
          <div className="form-row">
            <div className="form-group col-md-4">
              <label htmlFor="lblusername">Username <span className="required">*</span></label>
              <input type="text" className="form-control" id="inputusername" placeholder="User name" name="userUserName" onChange={this.props.handleChange} 
              required />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="lblpwd">Password <span className="required">*</span> </label>
              <input type="password" className="form-control" id="inputpwd" placeholder="Password" name="userPassword" onChange={this.props.handleChange}
              required/>
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="lblcnfpwd">Confirm Password <span className="required">*</span> </label>
              <input type="password" className="form-control" id="inputcnfpwd" placeholder="Confirm Password" onChange={this.props.handleChange}
              required/>
            </div>
          </div>
          <div className="form-row hide">
            <div className="form-group col-md-6">
              <label htmlFor="lblsecques">Security question <span className="required">*</span> </label>
              <select type="text" className="form-control" id="inputsecque" placeholder="Security Question" name="userQuestions" onChange={this.props.handleChange} >
                <option value="1">Select</option>
                <option value="2">Which city you born in?</option>
              </select>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="lblsecans">Phone No.<span className="required">*</span> </label>
              <input type="text" className="form-control" id="inputsecans" placeholder="Security Anwswer" name="userAnswer" onChange={this.props.handleChange} />
            </div>
          </div>
        </div>

      </>
    );
  }
}

export default UserRegister;
