import React, { Component } from "react";
import {
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBJumbotron,

} from "mdbreact";

import { fetchService } from "../utilities/GlobalFunctions";
import { Link,Redirect } from "react-router-dom";
class ProductLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal4: false,
      isLoading: true,
      mode: 0,
      LoadGridProduct: [],
      IsValidOwner: (this.props.location && this.props.state !== undefined && this.props.location.state !==null) 
      ? this.props.location.state.data:"OWNER"
    };
  }
  toggle = (nr) => () => {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber]
    });
  };

  async componentDidMount() {
    this.setState({ LoadGridProduct: await fetchService('ManageProduct', 'GetProductDetails', 'POST', localStorage.getItem('lsLoginName')) });
  }
  render() {
    const griddata = this.state.LoadGridProduct;
    // griddata.push({'handle':<a href="#!" className="btn-sm orange darken-3 white-text" onClick={this.toggle(4)}><MDBIcon icon="angle-double-right" /></a>});
    const data = {
      columns: [
        {
          label: "Manage",
          field: "handle",
          sort: "asc",
          width: 100
        },
        {
          label: "ID",
          field: "productID",
          sort: "asc",
          width: 100,
          show: false

        },
        // {
        //   label: "Image",
        //   field: "image",
        //   sort: "asc",
        //   width: 200
        // },

        {
          label: "Name",
          field: "productName",
          sort: "asc",
          width: 200
        },

        {
          label: "Quantity",
          field: "quantity",
          sort: "asc",
          width: 100
        },
        {
          label: "Price/Item",
          field: "price",
          sort: "asc",
          width: 100
        },
        // {
        //   label: "Discount",
        //   field: "discount",
        //   sort: "asc",
        //   width: 100
        // },
        // {
        //   label: "Status",
        //   field: "status",
        //   sort: "asc",
        //   width: 100
        // }
      ],
      rows: griddata.map(obj=> ({'handle':<Link className="btn-sm orange darken-3 white-text" to={{ pathname: '/ManageProduct', state: { mode: obj} }}> 
      <MDBIcon icon="angle-double-right" /></Link>,"ProductID":obj.productID,"ProductName":obj.productName,"Quantity":obj.quantity,"Price":obj.price}))
    };
    if(localStorage.getItem("lsLoginName") !== null &&
    this.state.IsValidOwner!==""){
    return (
      <>
       
          <MDBRow>
            <MDBCol className="mx-auto">
              <MDBJumbotron id="productlist" className="mt-3 p-5">
              <Link to={{ pathname: '/ManageProduct', state: { mode: "new" } }}>

                <MDBBtn
                  className="btn btn-primary btn-md orange darken-3"
                  // href='./ManageProduct'
                >
                  Add New Product
                </MDBBtn>
                </Link>
                <div className="overflow-x">                 
                  <MDBDataTable striped bordered small data={data} />
                </div>
              </MDBJumbotron>
            </MDBCol>
          </MDBRow>
      
        </>
    );
  }
  else {
  return  <Redirect to="/"></Redirect>
  }
  }
}

export default ProductLists;
