// import { async } from "q";
import React from "react";
//const domain='https://localhost:5001/api/'
const domain='https://eshopeapi.azurewebsites.net/api/'
// const domain='http://services.eshope.com/api/'
// const domain='http://serviceslb.eshope.com/api/'
//const domain='https://eshopeservices.azurewebsites.net/api/'
//const domain ="https://localhost:44338/api/";
const fetchService = async (controller, action, type, data) => {
  let url = domain + controller + '/' + action;
  const _fetch = type === 'Get' ? await fetch(url) : await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    },
  });
  console.log(_fetch);
  const _data = await _fetch;
  console.log(_data);
  return (_data.status === 200) ? _data.text().then(function (text) { return text ? JSON.parse(text) : {} }) :
  (_data.status === 302) ? "UNIQUEKEY":
    "E"
  // return  _data.text().then(function(text) {
  //   return text ? JSON.parse(text) : {}
  //  });  

}

const Spinner = props => {
  return (

    <div className="my-5 d-flex justify-content-around">
       <div className="spinner-grow text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
    </div>
  )
}

export { fetchService };
export default Spinner;