import React from "react";
import { MDBEdgeHeader, MDBCardBody, MDBFreeBird, MDBRow ,MDBCol } from 'mdbreact';

import "../index.css";
const OrderConfirmation = (props) => {
  return (
    <>
      <MDBEdgeHeader color="orange lighten-1" className="sectionPage" />
      <div className="mt-3 mb-5">
        <MDBFreeBird>
          <MDBRow className="orderpageheight">
            <MDBCol
              md="12"
              className="mx-auto float-none white z-depth-1 py-2 px-2"
            >
              <MDBCardBody className="text-center minheight450">
                <p className="mb-3 orange-text-dark3">
                  {/* <strong>EshopE, Welcome {localStorage.getItem('lsUserName')}</strong> */}
                  Order Confirmation
                </p>
                <hr className="my-1" />
                <div>
                  <p className="mb-3 h5 mt-5  text-success">
                    Your Order is Complete. <i class="fas fa-check"></i>
                  </p>
                  <p className="mb-3 h5 orange-text-dark3">
                    Please Track your order in 'Track Order' page
                  </p>
                  <p className="mb-3 orange-text-dark3">
                    <strong>
                      Thanks for Shopping. {localStorage.getItem("lsUserName")}
                    </strong>
                  </p>
                  
                        <br />
                        <a
                          color="primary"
                          className="btn btn-primary btn-md orange darken-3"
                          href='/trackorder'
                        >
                          Track your Order
                      </a>
                </div>{" "}
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBFreeBird>
      </div>
    </>
  );
};

export default OrderConfirmation;
