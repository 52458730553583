import React, { Component } from "react";
import {
  MDBEdgeHeader,
  MDBFreeBird,
  MDBModalFooter,
  MDBBtn,
  MDBCol,
  MDBRow,
  MDBCardBody,
 
} from "mdbreact";
import { MDBDataTable } from "mdbreact";
import "../index.css";
import Spinner, { fetchService } from "../utilities/GlobalFunctions";
import { Link, Redirect } from "react-router-dom";
class OrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LoadOrderList: [],
      isLoading: true,
      checkout: [],
      cartcount: "",
      IsValidOwner: (this.props.location.state && this.props.location.state.data !== undefined) ?this.props.location.state.data:"OWNER",
      shopID:  (this.props.location.state && this.props.location.state.shopID !== undefined) ?this.props.location.state.shopID:"OWNER",
    };
  }

  async componentDidMount() {
    console.log(this.props.location.state.orderid);
    console.log('orderid');
    this.setState.isLoading = true;
    const output = await fetchService(
      "OrderDetails",
      "GetOrderDetailsByOrderId_shop",
      "POST",
      this.props.location.state.orderid+','+ this.state.shopID+','+localStorage.getItem('lsLoginName')
    );

    if (output !== null) {
      this.setState({
        isLoading: false,
        LoadOrderList: output,
        cartcount: output.length,
      });
    }
  }

  render() {
    const griddata = this.state.LoadOrderList;
    console.log(griddata);
    const isLoading = this.state.isLoading;
    const data = {
      columns: [
        {
          label: "ProductName",
          field: "firstname",
          sort: "asc",
          
        },
        {
          label: "Total",
          field: "firstname",
          sort: "asc",
     
        },
        {
          label: "Status",
          field: "useraddress",
          sort: "asc",
   
        },
        {
          label: "CreatedDate",
          field: "usercity",
          sort: "asc",
        
        },
    
       
      ],
      rows:
        this.state.isLoading === false &&
        griddata.map((obj) => ({
          firstname: obj.firstname,
          useraddress:obj.useraddress,
          usercity: obj.usercity,
          statusvalue:obj.statusvalue,
         
         
        })),
    };
    const cartrowcount = this.state.cartcount;
    if (isLoading) {
      return <Spinner> Loading ...</Spinner>;
    } else if (
      localStorage.getItem("lsLoginName") !== null &&
      this.state.IsValidOwner!==""
    ) {
      if (cartrowcount > 0) {
        return (
          <>
            <MDBEdgeHeader color="orange lighten-1" className="sectionPage" />
            <div className="mt-3 mb-5">
              <MDBFreeBird>
                <MDBRow className="orderpageheight">
                  <MDBCol
                    md="12"
                    className="mx-auto float-none white z-depth-1 py-2 px-2"
                  >
                    <MDBCardBody className="text-center">
                      <p className="mb-3 orange-text-dark3">
                        <strong>
                          {/* Welcome {localStorage.getItem("lsUserName")} */}
                          Curstormer Order List
                        </strong>
                      </p>
                      
                      <hr className="my-1" />

                      <div className="minheight450">
                        <div >
                          <MDBDataTable id="carttable"
                            sortable
                            striped
                            bordered
                            small
                            paging={false}
                            searching={false}
                            data={data}
                           
                          />
                        </div>
                        <MDBModalFooter>
                          <table width="100%">
                            <tr>
                              <td align="left">
                                <p className="mb-3 h4 orange-text-dark3">
                                  {/* <strong><i class="fas fa-rupee-sign"></i>. {CartTotal}</strong> */}
                                </p>
                              </td>
                              <td align="right">
                                <Link
                                  color="primary"
                                  className="btn btn-primary btn-md orange darken-3"
                                  to={{
                                    pathname: "/CheckOut",
                                    state: { checkout: griddata },
                                  }}
                                >
                                  Complete{" "}
                                  <i class="fas fa-angle-double-right"></i>
                                </Link>
                              </td>
                            </tr>
                          </table>
                        </MDBModalFooter>
                      </div>
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </MDBFreeBird>
            </div>
          </>
        );
      } else {
        return (
          <>
            <MDBEdgeHeader color="orange lighten-1" className="sectionPage" />
            <div className="mt-3 mb-5">
              <MDBFreeBird>
                <MDBRow className="orderpageheight minheight450">
                  <MDBCol
                    md="12"
                    className="mx-auto float-none white z-depth-1 py-2 px-2"
                  >
                    <MDBCardBody className="text-center">
                      <div className="">
                        Your Cart is empty.
                        <hr />
                        <a href="/products">
                          {" "}
                          <i class="fas fa-3x fa-store orange-text darken-3 handcursor"></i>
                        </a>
                        <br />
                        <MDBBtn
                          color="primary"
                          className="btn btn-primary btn-sm orange darken-3"
                          href="/products"
                        >
                          Shop now
                        </MDBBtn>
                      </div>{" "}
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </MDBFreeBird>
            </div>
          </>
        );
      }
    } else {
      return <Redirect to="/login"></Redirect>;
    }
  }
}

export default OrderList;
