import React, { Component,useState,useEffect } from "react";
import Spinner, { fetchService } from "../utilities/GlobalFunctions";
import "../indexcopy.css";

const NumberedTextbox =(props)=>{
  const[loading,setLoading] = useState(true);
  const[click,setClick] = useState(props.input);
  const decrease = async () => {
    const data = {
      ProductID: parseInt(props.productid),
      increaseDecrease:'D',
      LoginName: localStorage.getItem("lsLoginName"),
    };
    if (click > 1) {
      
      const output = await fetchService(
        "CartDetails",
        "IncreaseDecreaseCartItems",
        "POST",
        data
      );
      if (output != null) {
        localStorage.setItem("lscount", parseInt(localStorage.getItem("lscount"))-1);
        setClick(output);
        setTimeout(() => window.location.reload(), 100);
      }
    }
  };

  const increase = async () => {
    const data = {
      ProductID: parseInt(props.productid),
      increaseDecrease:'I',
      LoginName: localStorage.getItem("lsLoginName"),
    };
    console.log(data);
  
    const output = await fetchService(
      "CartDetails",
      "IncreaseDecreaseCartItems",
      "POST",
      data
    );
    if (output != null) {
      localStorage.setItem("lscount", parseInt(localStorage.getItem("lscount"))+1);
      setClick(output);
      setTimeout(() => window.location.reload(), 100);
    }
  };

  return (
    <>

      <div class="action-wrap center" >
        <div class="input-group mb-1">
          <div class="input-group-prepend">
            <span class="input-group-text cartprice">
              <i class="fas fa-minus-circle fa-sm orange-text handcursor" onClick={decrease}>
               
              </i>
            </span>
          </div>
          <div class="input-group-prepend">
            <input
              className="cartprice quantitytextbox"
              name="quantity"
              value={click}
              onChange={() => console.log("change")}
            
            />
          </div>
          <div class="input-group-append">
            <span class="input-group-text cartprice" id="basic-addon2">
             
              <div>
                <i class="fas fa-plus-circle fa-sm orange-text handcursor" onClick={increase}></i>
              </div>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

// class NumberedTextbox extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isLoading: true,
//       value: this.props.input,
//     };
//   }

//   decrease = () => {
//     if (this.state.value > 1) {
//       this.setState({ value: this.state.value - 1 });
//     }
//   };

//   increase = () => {
//     this.setState({ value: this.state.value + 1 });
//   };

//   render() {
//     return (
//       <>
//          {/* <div className="def-number-input number-input">
//           <button onClick={this.decrease} className="minus"></button>
//           <input
//             className="quantity"
//             name="quantity"
//             value={this.state.value}
//             onChange={() => console.log("change")}
//             type="number"
//           />
//           <button onClick={this.increase} className="plus"></button>
//         </div>  */}

//         <div class="action-wrap center" >
//           <div class="input-group mb-1">
//             <div class="input-group-prepend">
//               <span class="input-group-text cartprice">
//                 <i class="fas fa-minus-circle fa-sm orange-text handcursor" onClick={this.decrease}>
                 
//                 </i>
//               </span>
//             </div>
//             <div class="input-group-prepend">
//               <input
//                 className="cartprice quantitytextbox"
//                 name="quantity"
//                 value={this.state.value}
//                 onChange={() => console.log("change")}
              
//               />
//             </div>
//             <div class="input-group-append">
//               <span class="input-group-text cartprice" id="basic-addon2">
               
//                 <div>
//                   <i class="fas fa-plus-circle fa-sm orange-text handcursor" onClick={this.increase}></i>
//                 </div>
//               </span>
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   }
// }

export default NumberedTextbox;
