import React from "react";
import { MDBEdgeHeader, MDBCardBody, MDBFreeBird, MDBRow ,MDBCol } from 'mdbreact';

import "../index.css";
const Logout = (props) => {
  return (
    <>
      <MDBEdgeHeader color="orange lighten-1" className="sectionPage" />
      <div className="mt-3 mb-5">
        <MDBFreeBird>
          <MDBRow className="orderpageheight">
            <MDBCol
              md="12"
              className="mx-auto float-none white z-depth-1 py-2 px-2"
            >
              <MDBCardBody className="text-center minheight450">
                <p className="mb-3 orange-text-dark3">
                  You have been Logged out
                </p>
         
                <div>
                
                  <p className="mb-3 orange-text-dark3">
                    <strong>
                      Thanks for Shopping. {localStorage.getItem("lsUserName")}
                    </strong>
                  </p>
                </div>{" "}
              </MDBCardBody>
            </MDBCol>
          </MDBRow>
        </MDBFreeBird>
      </div>
    </>
  );
};

export default Logout;
