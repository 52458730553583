import React from "react";
import {
  MDBEdgeHeader,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBJumbotron,
  MDBIcon,
  MDBAnimation,
  MDBAlert
} from "mdbreact";
//import GoogleLogin from "react-google-login";
import SectionContainer from "../components/sectionContainer";
import UserRegister from "./UserRegister";
import "../index.css";
import { fetchService } from "../utilities/GlobalFunctions";


class RegisterBuyer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userUserName: '',
      userPassword: '',
      userFirstName: '',
      userMiddleName: '',
      userLastName: '',
      userDateofbirth: '',
      userEmail: '',
      userMobileNo: '',
      userPhoneNo: '',

      isLoading: false,
      cssClass: "hide",
      UserRegMsg: "",
      UserRegMsgColor: ""
    }
    this.postData.postData = this.postData.postData.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  async componentDidMount() {
    this.setState({
      getCodeValue: await fetchService('ShopLocation', 'GetCodeValue', 'POST','ShopType'),
      isLoading: false

    });
    // console.log(this.state.getCodeValue)
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  postData = {
    postData: async event => {
      event.preventDefault();
      // postData = async () => {
      this.setState({
        isLoading: true
      })
      const data = {
        // user
        userUserName: this.state.userUserName,
        userPassword: this.state.userPassword,
        userFirstName: this.state.userFirstName,
        userMiddleName: this.state.userMiddleName,
        userLastName: this.state.userLastName,
        userDateofbirth: this.state.userDateofbirth,
        userEmail: this.state.userEmail,
        userMobileNo: this.state.userMobileNo,
        userPhoneNo: this.state.userPhoneNo,
       
      }

      const output_save = await fetchService('UserRegistration', 'SaveUserData', 'POST', data);
console.log(output_save);

      if (output_save === 200) {

        this.setState({

          isLoading: false,
          cssClass: "show",
          UserRegMsg: "Sucessfully registered. Welcome to EshopE.",
          UserRegMsgColor: "success"

        });
        setTimeout(() => window.location.reload(), 5000);

      } 
      else if (output_save === 208) {
        this.setState({
          isLoading: false,
          cssClass: "show",
        UserRegMsg: "Username/Mobile no./EmailId is already registered. Please contact us or use different details to register",
        UserRegMsgColor: "danger"
        });
      }
      
      
      else {
        this.setState({
          isLoading: false,
          cssClass: "show",
          ShopRegMsg: "Please enter correct details and register",
          ShopRegMsgColor: "danger"
        });
        //setTimeout(() => window.location.reload(), 1000);

      }


      console.log(output_save);

    }
  }
  render() {
    const message = this.state.UserRegMsg;
    return (
  
    <>
      <MDBEdgeHeader color="orange lighten-1" className="sectionPage" />
      <MDBAnimation type="zoomIn" duration="500ms">
        <MDBContainer>
          <MDBRow>
            <MDBCol md="10" className="mx-auto">
              <MDBJumbotron className="mt-3">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <h3 className="text-center">
                      <span className="orange-text small px-2">
                        <MDBIcon icon="envelope" />
                      </span>
                      User Registration
                    </h3>
                    </div>
                  <div className="form-group col-md-6">
                    {/* <div id="googlelogin" className="text-center"> 
                      <GoogleLogin
                        clientId="632080973487-vtvdhjl1tcqpfj6q75596of378h4v1il.apps.googleusercontent.com"
                        buttonText="Login"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={"single_host_origin"}
                      />
                    </div>*/}
                  </div>
                  <div className="form-group col-md-6"></div>
                </div>
                <MDBContainer>
                  <SectionContainer>
                  <MDBAlert
                      color={this.state.UserRegMsgColor}
                      className={this.state.cssClass}
                    >
                      {message}
                    </MDBAlert>
                    <form id="userregister" onSubmit={this.postData.postData}>
                      <UserRegister handleChange={this.handleChange}  />
                      <button type="submit" className="btn btn-primary btn-md orange darken-3">
                        Register
            </button>
                    </form>
                  </SectionContainer>

                </MDBContainer>
              </MDBJumbotron>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBAnimation>
    </>
  );
}}
export default RegisterBuyer;
