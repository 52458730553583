import React from "react";
import {
  MDBEdgeHeader,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBJumbotron,
  MDBIcon,
  MDBAnimation
} from "mdbreact";
//import MenuLink from "./../components/menuLink";

const CSSPage = () => {
  return (
    <>
    <MDBEdgeHeader color="orange lighten-1" className="sectionPage" />
      <MDBAnimation type="zoomIn" duration="500ms">
        <MDBContainer>
          <MDBRow>
            <MDBCol md="10" className="mx-auto">
                <MDBJumbotron className="mt-3" >
                  <h3 className="text-center">
                    <span  className='orange-text small' ><MDBIcon icon="users"  /></span>
                     About Us
                  </h3>
                  
                  <p className="p-2">
                    This application shows the actual use of MDB React
                    components in the application.
                  </p>
                  <p className="p-2">
                    This application shows the actual use of MDB React
                    components in the application.
                    This application shows the actual use of MDB React
                    components in the application.
                    This application shows the actual use of MDB React
                    components in the application.
                    This application shows the actual use of MDB React
                    components in the application.
                    This application shows the actual use of MDB React
                    components in the application.
                  </p>
                  <p className="p-2">
                    This application shows the actual use of MDB React
                    components in the application.
                    This application shows the actual use of MDB React
                    components in the application.
                    This application shows the actual use of MDB React
                    components in the application.
                    This application shows the actual use of MDB React
                    components in the application.
                    This application shows the actual use of MDB React
                    components in the application.
                  </p>
                  <p className="p-2">
                    This application shows the actual use of MDB React
                    components in the application. This application shows the actual use of MDB React
                    components in the application.This application shows the actual use of MDB React
                    components in the application.This application shows the actual use of MDB React
                    components in the application.This application shows the actual use of MDB React
                    components in the application.This application shows the actual use of MDB React
                    components in the application.
                  </p>
                </MDBJumbotron>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBAnimation>
    </>
  );
};

export default CSSPage;
