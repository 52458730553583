import React, { Component } from "react";
import {
  MDBEdgeHeader,
  MDBFreeBird,
  MDBModalFooter,
  MDBCol,
  MDBRow,
  MDBCardBody,
  MDBBtn
} from "mdbreact";
import { MDBDataTable } from "mdbreact";
import { Redirect } from "react-router-dom";
import { fetchService } from '../utilities/GlobalFunctions';
import "../index.css";
class CheckOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LoadCartDetails: [],
      isLoading: true,
      checkout: [],
      loggedinUsername:this.props.location && this.props.location.state!==undefined && this.props.location.state !==null 
      && this.props.location.state.loggedinUsername,
      userRole: this.props.location && this.props.location.state!==undefined && this.props.location.state !==null 
      && this.props.location.state.userRole,
      checkoutData: this.props.location && this.props.location.state !== undefined && this.props.location.state !==null
      && this.props.location.state.checkout
    };
  }
  componentDidMount() {
    // this.setState({checkout:this.props.location.checkout, isLoading:false});
  }
  CheckOut = {
    addOrder: async () => {
      const data = this.state.checkoutData;

    console.log(data);
      const output_save = await fetchService('OrderDetails', 'SaveOrderDetails', 'POST', data);
      if (output_save === 200) {
       console.log(localStorage.getItem('lsOrder'));
       this.props.history.push('/OrderConfirmation/');
       localStorage.setItem('lscount',0);
       this.setState({isLoading:false});
      }
      console.log(output_save);
    }
  }
  render() {
    const griddata = this.state.checkoutData;
    const data = {
      columns: [
        {
          label: "Name",
          field: "productName",
          sort: "asc",
         

        },
        {
          label: "Price",
          field: "price",
          sort: "asc",
         

        },
        {
          label: "Quantity",
          field: "numbers",
          sort: "asc",
          

        }
      ],
      rows: this.state.checkoutData.map(obj => ({
        // 'handle':<MDBBtn className="btn-sm orange darken-3 white-text" >
        //  <MDBIcon icon="angle-double-right" /></MDBBtn>,
        "productName": obj.productName
        , "price": obj.price
        , "numbers": obj.numberofItems
      }))
    };
    if (this.state.loggedinUsername!=='' && this.state.userRole==='USER') {
      return (
        <>
          <MDBEdgeHeader color="orange lighten-1" className="sectionPage" />
          <div className="mt-3 mb-5" >
            <MDBFreeBird>
              <MDBRow className="orderpageheight">
                <MDBCol
                  md="12"
                  className="mx-auto float-none white z-depth-1 py-2 px-2"
                >
                  <MDBCardBody className="text-center minheight450">
                    <p className="mb-3 orange-text-dark3">  Check out </p>
                    <hr className="my-1" />
                    <div>
                      <MDBDataTable id="ordertable" sortable striped bordered small searching={false} data={data} />
                    </div>
                    <MDBModalFooter>
                       <MDBBtn
                          color="primary"
                          className="btn btn-primary btn-md orange darken-3"
                          onClick={this.CheckOut.addOrder}
                        >
                          Order Now
                      </MDBBtn>
                    </MDBModalFooter>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBFreeBird>
          </div>
        </>
      )
    }
    else {
      return <Redirect to="/login"></Redirect>
    }
  }
}
export default CheckOut;
