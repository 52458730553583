import React, { Component } from "react";
import {

  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
} from "mdbreact";
import CustomerOrders from "./CustomerOrders";
import ProductLists from "./ProductLists";


import "../index.css";

class TabsDefault extends Component {
  constructor(props){
    super(props);
    this.state = {
      activeItem: "1",
      cssClass: "activetab",
      shopID: this.props.shopID
    };
  }
 

  toggle = tab => e => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
        cssClass: "activetab"
      });
    }
  };
  componentDidMount(){
    if(localStorage.getItem("lsRedirect") ==="Product"){
     
      this.setState({activeItem: "2",cssClass:"activetab"});

    }
    else {
      
      this.setState({activeItem: "1",cssClass:"activetab"});
    }
  }

  render() {
    // const activetab = "activetab";
    // const notactivetab = "notactivetab";
    return (
      <>

        <MDBNav className="nav-tabs mt-2">
          <MDBNavItem>
            <MDBNavLink
              to="#"
              active={this.state.activeItem === "1"}
              onClick={this.toggle("1")}
              role="tab"
              className={this.state.activeItem === "1" && this.state.cssClass}
            >
              Customer Order
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink
              to="#"
              active={this.state.activeItem === "2"}
              onClick={this.toggle("2")}
              role="tab"
              className={this.state.activeItem === "2" && this.state.cssClass}
            >
              Manage Products
            </MDBNavLink>
          </MDBNavItem>

        </MDBNav>
        <MDBTabContent activeItem={this.state.activeItem}>
          <MDBTabPane tabId="1" role="tabpanel">
            
              <CustomerOrders shopID={this.state.shopID} />
           
          </MDBTabPane>
          <MDBTabPane tabId="2" role="tabpanel">

            <ProductLists shopID ={this.state.shopID} />
          </MDBTabPane>

        </MDBTabContent>


      </>);
  }
}
export default TabsDefault;
