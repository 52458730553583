import React from "react";
import {
  MDBEdgeHeader,
  MDBFreeBird,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBCardBody,
  // MDBIcon,
  // MDBCard,
  // MDBCardTitle,
  // MDBCardImage,
  // MDBCardText,

  // MDBNavLink,
  // MDBBtn

} from "mdbreact";
import "../index.css";
import "../HomePage.css";

import ShopOwnerTabs from "./ShopOwnerTabs";
import { Redirect } from "react-router-dom";

//import RegisterShopNavPage from "./RegisterShopNavPage";

class ShopOwnerOrder extends React.Component {
  scrollToTop = () => window.scrollTo(0, 0);

  constructor(props) {
    super(props);
    this.state = {
      activeItemClassicTabs2: "1",
      userRole: (this.props.location.state && this.props.location.state.data !== undefined) ? this.props.location.state.data:"",
      shopID: (this.props.location.state && this.props.location.state.shopID !== undefined) ?this.props.location.state.shopID: 0
    }
  };

  toggleClassicTabs2 = tab => () => {
    if (this.state.activeItemClassicTabs2 !== tab) {
      this.setState({
        activeItemClassicTabs2: tab
      });
    }
  };

  render() {
    if (localStorage.getItem('lsLoginName') !== null && this.state.userRole==='OWNER' ) {
      return (
        <>

          <MDBEdgeHeader color="orange lighten-1" className="sectionPage" />
          <div className="mt-3 mb-5">
            <MDBFreeBird>
              <MDBRow className="orderpageheight">
                <MDBCol
                  md="12"
                  className="mx-auto float-none white z-depth-1 py-2 px-2"
                >
                  <MDBCardBody className="text-center">
                    <h3 className="h2-responsive mb-3 orange-text-dark3">
                      <strong>{localStorage.getItem('lsShopName')}</strong>
                    </h3>
                    <hr className="my-1" />

                    <ShopOwnerTabs shopID={this.state.shopID}/>

                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBFreeBird>

            <MDBContainer>
              <MDBRow>
                <MDBCol md="12" className="mt-4">
                  <p className="text-center text-muted mb-1">
                    Google has designed a Material Design to make the web more
                    beautiful and more user-friendly.
                </p>
                </MDBCol>
              </MDBRow>
            </MDBContainer>

          </div>


        </>
      );
    }
    else {
      return <Redirect to="/"></Redirect>
    }
  }
}

export default ShopOwnerOrder;
