import React, { Component } from "react";
import {
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBEdgeHeader,
  MDBFreeBird,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBCardBody
} from "mdbreact";

import ProductDetails from "./ProductDetails";
import Reviews from "./Reviews";
import  { fetchService } from '../utilities/GlobalFunctions';

import "../index.css";


class ProductDetailsTab extends Component {
  state = {
    activeItem: "1",
    cssClass: "activetab",
    getdropdowndetails: [],
    isLoading: true,
    CodeID: '',
    CodeValue: '',
    CodeDescription: '',
    Data1: '',
    CreatedBy: '',
    UpdatedBy: '',
    cssSuccessMessage:'hide'
  };

  Adminproduct = {

    addProduct: async () => {
      const data = {
     
        CreatedBy: localStorage.getItem('lsLoginName'),
        UpdatedBy: localStorage.getItem('lsLoginName')
      }
      const output_save = await fetchService('CodeValue', 'SaveData', 'POST', data);
      if (output_save === 200) {
        this.setState({cssSuccessMessage:'show'});
      }
      console.log(output_save);
    }
  }
  toggle = tab => e => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
        cssClass: "activetab"
      });
    }
  };
  async componentDidMount() {
    this.setState({
      getdropdowndetails: await fetchService('ShopLocation', 'GetCodeID', 'Get'),
      isLoading: false

    });
    console.log(this.state.getdropdowndetails)
  }

  render() {
    // const activetab = "activetab";
    // const notactivetab = "notactivetab";
   
      return (
        <>
          <MDBEdgeHeader color="orange lighten-1" className="sectionPage" />
          <div className="mt-3 mb-5" >
            <MDBFreeBird>
              <MDBRow className="orderpageheight">
                <MDBCol
                  md="12"
                  className="mx-auto float-none white z-depth-1 py-2 px-2"
                >
                  <MDBCardBody className="text-center">
                    <p className="mb-3 orange-text-dark3">
                      <strong>Product Name</strong>
                    </p>
                    <hr className="my-1" />
                    <MDBNav className="nav-tabs mt-2">
                      <MDBNavItem>
                        <MDBNavLink
                          to="#"
                          active={this.state.activeItem === "1"}
                          onClick={this.toggle("1")}
                          role="tab"
                          className={
                            this.state.activeItem === "1" && this.state.cssClass
                          }
                        >
                          Product details
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem>
                        <MDBNavLink
                          to="#"
                          active={this.state.activeItem === "2"}
                          onClick={this.toggle("2")}
                          role="tab"
                          className={
                            this.state.activeItem === "2" && this.state.cssClass
                          }
                        >
                         Reviews
                        </MDBNavLink>
                      </MDBNavItem>
                       </MDBNav>
                    <MDBTabContent className="minheight350" activeItem={this.state.activeItem}>
                      <MDBTabPane tabId="1" role="tabpanel">
                       
                          <ProductDetails/> 
                      </MDBTabPane>
                      <MDBTabPane tabId="2" role="tabpanel">
                      <Reviews/> 
                      </MDBTabPane>
                  
                    </MDBTabContent>
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBFreeBird>

            <MDBContainer>
              <MDBRow>
                <MDBCol md="12" className="mt-4">
                  <p className="text-center text-muted mb-1">
                    View the product details >  Add to Cart
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </>
      );
    
}
  }

export default ProductDetailsTab;
