import React, { Component } from "react";
import { Link } from "react-router-dom";

import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBFooter,
  MDBNavLink,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBDropdown,
  MDBDropdownToggle,
  MDBBadge,
} from "mdbreact";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import Routes from "./Routes";
import SearchShop from "./mypages/SearchShop";
import CartTable from "./OrderPages/CartTable";
import UserMenu from "./mypages/UserMenu";
import "./index.css";
import { fetchService } from "./utilities/GlobalFunctions";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseID: "",
      ModalLogin: false,
      Modal15: false,
      Modal16: false,
      radio: "",
      dynamicMenu: null,
      isLoading: true,
      loginUsername: "",
      
      loginPassword: "",
      loginValid: false,
      logout: false,
      cssClass: "hide",
      loading: false,
      shopID: 0,
    };
  }

  async componentDidMount() {
    if (localStorage.getItem("lsLoginName") !== null) {
      this.setState({ loginClass: "hide" });
      const data = {
        userUserName: localStorage.getItem("lsLoginName"),
      };
      const _validate = await fetchService(
        "UserRegistration",
        "LoggedinUser",
        "POST",
        data
      );
      console.log(_validate); // need to check it is sucess and failure value
      if (
        localStorage.getItem("lsLoginSource") === "GOOGLE" 
        //&&
        // (localStorage.getItem("lsLoginName") !== "" ||
        //   localStorage.getItem("lsLoginName") !== null)
      ) {
        console.log("Google login success");
        this.setState({
          cssClass: "show",
          loginClass: "hide",
          userRole: "USER",
          loggedinUsername:localStorage.getItem("lsLoginName"), //google first name name 
          loggedinFirstName:  localStorage.getItem("lsSocialloggedinUsername"), //// gmail id from google
        });
        console.log(this.state.loggedinUsername);
      } else if (_validate !== "E") {
      
        console.log("Eshope login success");
        this.setState({
          cssClass: "show",
          loginClass: "hide",
          userRole: _validate.userRole,
          loggedinUsername: _validate.userUserName,
          loggedinFirstName: _validate.userFirstName,
          shopID: _validate.shopId,
        });
       
      } else {
        this.setState({ loginClass: "show" });
      }
    }
  }

  login = {
    toggleCollapse: (collapseID) => () =>
      this.setState((prevState) => ({
        collapseID: prevState.collapseID !== collapseID ? collapseID : "",
      })),

    closeCollapse: (collapseID) => () => {
      window.scrollTo(0, 0);
      this.state.collapseID === collapseID && this.setState({ collapseID: "" });
    },
    toggle: (nr) => (e) => {
      if (nr === 15) {
        let modalNumber = "Modal" + nr;
        this.setState({
          [modalNumber]: !this.state[modalNumber],
          cssClass: "hide",
        });
      }

      if (nr === "Login") {
        let modalNumber = "Modal" + nr;
        this.setState({
          [modalNumber]: !this.state[modalNumber],
          cssClass: "hide",
        });
        console.log(this.state[modalNumber]);
        e.preventDefault();
      } else if (nr === "Manage Order") {
        localStorage.setItem("lsRedirect", "Order");
      } else if (nr === "Manage Product") {
        localStorage.setItem("lsRedirect", "Product");
      } else if (nr === "Logout") {
        localStorage.removeItem("lsShopName");
        localStorage.removeItem("lsUserName");
        // localStorage.removeItem('lsValidLogin');
        localStorage.removeItem("lsLoginName");
        // localStorage.removeItem('lsRole');
        localStorage.setItem("lscount", "0");
        // localStorage.removeItem('lsLogout');
        // localStorage.removeItem('lsCssLoginClass');
        // localStorage.removeItem('lsCssuserIcon');
        // localStorage.removeItem('lsGotoLogin');

        //this.setState({ cssClass: 'show', loginClass: 'show', userIcon: 'hide', logout: true });
        // return <Redirect to="/ShopOwnerOrder"></Redirect>
      } else {
        if (localStorage.getItem("lsLoginName") === null) {
          return <Redirect to="/"></Redirect>;
        }
      }
    },
  };

  render() {
    const { collapseID } = this.state;
    // const role = this.props.location && this.props.location.state !== undefined && this.props.location.state.role;
    console.log("from app page", this.state.role);
    const static_menu = [
      { key: 1, value: "Home", link: "/" },
      { key: 2, value: "Shopping", link: "/products" },
      { key: 3, value: "About", link: "/about" },
      { key: 4, value: "Services", link: "/services" },
      { key: 5, value: "Contact", link: "/contact" },
      // , { key: 6, value: 'Associates', link: '/associates' }
      // , { key: 7, value: 'Register-User', link: '/registeruser' }
      //, { key: 8, value: 'Upload-Image', link: '/imageupload' }
      // , { key: 8, value: 'ShopOwnerHome', link: '/ShopOwnerHome' }
      { key: 9, value: "Register-Shop", link: "/registershop" },
      { key: 10, value: "SignIn/Up", link: "/login" },
    ];

    const overlay = (
      <div
        id="sidenav-overlay"
        style={{ backgroundColor: "transparent" }}
        onClick={this.login.toggleCollapse("mainNavbarCollapse")}
      />
    );

    return (
      <Router>
        <div className="flyout">
          <MDBNavbar
            className="orange darken-3"
            dark
            expand="md"
            fixed="top"
            scrolling
          >
            <MDBNavbarBrand
              href="/"
              className="py-0 font-weight-bold"
              onClick={this.login.closeCollapse("mainNavbarCollapse")}
            >
              <strong className="align-middle logo">EshopE</strong>
            </MDBNavbarBrand>
            <MDBNavbarToggler
              onClick={this.login.toggleCollapse("mainNavbarCollapse")}
            />
            <MDBCollapse
              id="mainNavbarCollapse"
              isOpen={this.state.collapseID}
              navbar
            >
              <MDBNavbarNav right>
                {static_menu.map((menu) => (
                  <MDBNavItem>
                    <MDBNavLink
                      exact
                      // to={menu.link}
                      onClick={this.login.closeCollapse("mainNavbarCollapse")}
                      //  onClick=  {menu.value === 'Login' && this.login.toggle('Login')}
                      className={
                        (menu.value === "SignIn/Up" ||
                          menu.value === "Register-Shop") &&
                        this.state.loginClass
                      }
                      to={{
                        pathname: menu.link,
                        state: {
                          loggedinUsername: this.state.loggedinUsername,
                          userRole: this.state.userRole,
                        },
                      }}
                    >
                      <strong>{menu.value}</strong>
                    </MDBNavLink>
                  </MDBNavItem>
                ))}
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBNavbar>
          {collapseID && overlay}
          <main style={{ marginTop: "3.3rem" }}>
            <div>
              <MDBNavbar className="grey darken-3 greymenu" dark expand="md">
                {this.state.loggedinUsername && (
                  <MDBDropdown>
                    <MDBDropdownToggle nav>
                      <span className="welcomefont">
                        {"Welcome,  " + this.state.loggedinFirstName}{" "}
                      </span>
                    </MDBDropdownToggle>
                  </MDBDropdown>
                )}
                |
                <MDBDropdown>
                  <MDBDropdownToggle nav onClick={this.login.toggle(15)}>
                    <i class="fas fa-map-marker-alt white-text"></i>
                  </MDBDropdownToggle>
                </MDBDropdown>
                |
                {this.state.loggedinUsername !== "" &&
                  this.state.userRole === "USER" && (
                    <>
                      <Link
                        to={{
                          pathname: "/CartTable",
                          state: {
                            loggedinUsername: this.state.loggedinUsername,
                            userRole: this.state.userRole,
                          },
                        }}
                      >
                        {/* <a href="/CartTable" > */}
                        <MDBDropdown>
                          <span className="white-text px-2">
                            <i class="fas fa-cart-arrow-down"></i>{" "}
                            <MDBBadge
                              color="cartbadge orange darken-3"
                              className="ml-1"
                            >
                              {localStorage.getItem("lscount")}
                            </MDBBadge>
                          </span>
                        </MDBDropdown>
                      </Link>
                      <div className="pl-4">|</div>
                    </>
                  )}
                {this.state.loggedinUsername !== "" && (
                  <UserMenu
                    class={this.state.cssClass}
                    userRole={this.state.userRole}
                    shopID={this.state.shopID}
                    togglefunction={(event) => this.login.toggle(event)}
                  ></UserMenu>
                )}
              </MDBNavbar>
            </div>
          </main>
          <main style={{ marginTop: "0.9rem" }}>
            <Routes />
          </main>
          <MDBFooter className="grey darken-3">
            <p className="footer-copyright mb-0 py-3 text-center">
              &copy; {new Date().getFullYear()} Copyright:
              <a href="https://www.eshope.com"> eshope.com </a>
            </p>
          </MDBFooter>
          <MDBFooter className="grey darken-2">
            <p className="footer-copyright mb-0 py-3 text-center">
              <a href="/contact"> Contact Us </a>
              <hr />
              <a href="/policydocument"> Policy Document </a>
              <hr />
              <a href="/associates"> Key Associates </a>
              <hr />
              Developed by: The Unique Creations
            </p>
          </MDBFooter>
        </div>

        {/* model location  */}
        <MDBModal
          isOpen={this.state.Modal15}
          toggle={this.login.toggle(15)}
          centered
        >
          <MDBModalHeader toggle={this.login.toggle(15)}>
            Select your shop location
          </MDBModalHeader>
          <MDBModalBody>
            <form>
              <div>
                <SearchShop />
              </div>
            </form>
          </MDBModalBody>
          <MDBModalFooter>Happy Shopping</MDBModalFooter>
        </MDBModal>

        {/* model Cart  */}
        <MDBModal
          isOpen={this.state.Modal16}
          toggle={this.login.toggle(16)}
          centered
        >
          <MDBModalHeader toggle={this.login.toggle(16)}>
            Your Cart
          </MDBModalHeader>
          <MDBModalBody>
            <form>
              <CartTable />
            </form>
          </MDBModalBody>
        </MDBModal>
      </Router>
    );
  }
}
export default App;
