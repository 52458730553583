import React, { Component } from "react";
import {
  MDBDataTable,
  MDBBtn,
  MDBIcon,
  MDBRow,
  MDBCol,
 
  // MDBModal,
  // MDBModalBody,
  // MDBModalHeader,
  // MDBModalFooter
} from "mdbreact";
import { fetchService } from "../utilities/GlobalFunctions";

class ManageShop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LoadGridShopOwners: [],
      dynamicMenu: null,
      isLoading: true
    };
  }
 
  async componentDidMount() {
  this.GetShopOwnerDetails();
    //console.log(this.state.LoadGridShopOwners);
  }

  GetShopOwnerDetails= async() => {
    const output = await fetchService('UserRegistration', 'GetShopOwners', 'POST', localStorage.getItem('lsLoginName'));
   
    if (output !=null ) {
     this.setState({
       isLoading: false,
       LoadGridShopOwners: output
     }); 
    }
  }

  ToggelUserStatus = (userid) =>{
    console.log(userid);
    this.setState({isLoading:true})
    const output =  fetchService('Admin', 'ToggleUserStatus', 'POST', parseInt(userid) );
    if (output != null ) {
     console.log(output);     
    this.GetShopOwnerDetails();
    this.setState({isLoading: false});
     }
  }
  render() {
    const griddata = this.state.LoadGridShopOwners;
    const data = {
      columns: [
        {
          label: "Manage",
          field: "handle",
          sort: "asc",
          width: 100
        },
        {
          label: "Shop Name",
          field: "shopName",
          sort: "asc",
          width: 200
        },
        {
          label: "Owner Name",
          field: "ownerFullName",
          sort: "asc",
          width: 200
        },

        {
          label: "Mobile",
          field: "ownerMobileNo",
          sort: "asc",
          width: 200
        },

        {
          label: "Email",
          field: "ownerEmail",
          sort: "asc",
          width: 100
        },
        {
          label: "Role",
          field: "istrRoleName",
          sort: "asc",
          width: 100
        },
        {
          label:"Shop Type",
          field:"shopType",
          sort : "asc",
          width: 100
        },
        {
          label: "Status",
          field: "status",
          sort: "desc",
          width: 100
        }
        
      ],
      rows: this.state.isLoading===false && griddata.map(obj=> ({'handle':<MDBBtn className="btn-sm orange darken-3 white-text" >
         <MDBIcon icon="angle-double-right" /></MDBBtn>,
      "shopName":obj.shopName,"FullName":obj.ownerFullName,"OwnerMobileNo":obj.ownerMobileNo,"OwnerEmail":obj.ownerEmail
      ,"istrRoleName":obj.istrRoleName,"shopType":obj.shopType
      , "status": 
      <MDBBtn className={ obj.ownerStatus === 'Active'? "btn-sm green darken-3 white-text": "btn-sm red darken-3 white-text"} onClick={(e) => this.ToggelUserStatus(obj.userID)}>          
        {obj.ownerStatus} </MDBBtn>}))
    };
    return (
      <>
        
          <MDBRow>
            <MDBCol className="mx-auto">
              <div id="productlist" className="p-2">
                <h5 className="text-center">Manage Shop</h5>
                <MDBBtn
                  className="btn btn-primary btn-md orange darken-3"
                  // onClick={this.toggle(4)}
                >
                  Add New Shop
                </MDBBtn>
                <div className="overflow-x">
                  <MDBDataTable striped bordered small data={data} />
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        

        </>
    );
  }
}

export default ManageShop;
