import React from "react";
import { fetchService } from "../utilities/GlobalFunctions";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBJumbotron,
  MDBIcon,
  MDBAlert,
  MDBBtn,
} from "mdbreact";
import { Redirect, Link, Route } from "react-router-dom";
import HomePage from "./HomePage";
import ReactDOM from "react-dom";
import "../index.css";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginUsername: "",
      loginPassword: "",
      loginValid: false,
      logout: false,
      cssClass: "hide",
      loading: false,
      LoginMsg: "",
      LoginMsgColor: "",
      userRole: "",
      loggedinUsername: "",
      loginsource:""
    };

    this.login.handleChange = this.login.handleChange.bind(this);
    this.login.loginSubmit = this.login.loginSubmit.bind(this);
    localStorage.removeItem("lsGotoLogin");
    localStorage.removeItem("lsShopName");
    localStorage.removeItem("lsUserName");
    localStorage.removeItem("lsValidLogin");
    localStorage.removeItem("lsLoginName");
    localStorage.removeItem("lsRole");
    localStorage.setItem("lscount", "0");
    // localStorage.removeItem("lsshopid");
    // localStorage.setItem("lsCssLoginClass", "show");
    // localStorage.setItem("lsCssuserIcon", "hide");
    localStorage.removeItem("lsGotoLogin");
    // localStorage.removeItem("lsToken");
    console.log("correct loop");
  }

  login = {
    loginSubmit: async (event) => {
      event.preventDefault();
      const data = {
        userUserName: this.state.loginUsername,
        userPassword: this.state.loginPassword,
      };
      this.setState({ loading: true });
      const _validate = await fetchService(
        "UserRegistration",
        "Login",
        "POST",
        data
      );

      if (_validate !== "E") {
        localStorage.setItem("lsShopName", _validate.shopName);
        // localStorage.setItem("lsUserName", _validate.fullName);
        localStorage.setItem("lsLoginName", _validate.userUserName);
        // localStorage.setItem("lsRole", _validate.userRole);
        localStorage.setItem("lscount", _validate.customerCartCount);
        // localStorage.setItem("lsshopid", _validate.shopId);
        localStorage.setItem("lsToken",_validate.token)
        // localStorage.setItem("lsValidLogin", "true");
        //this.login.LoadMenu();
        // localStorage.setItem("lsCssLoginClass", "hide");
        // localStorage.setItem("lsCssuserIcon", "show");
        this.setState({
          logout: false,
          loading: false,
          cssClass: "show",
          loginClass: "show",
          LoginMsg: "Login Success.Happy Shopping.",
          LoginMsgColor: "success",
          userRole: _validate.userRole,
          loggedinUsername: _validate.userUserName,
        });
        // return <Redirect to={{pathname:"/",state: { data: this.state.loggedinUsername,role: this.state.userRole, loginClass:"hide", usericonClass:"show" }}}></Redirect>
        setTimeout(() => window.location.reload(), 100);
        //return <Redirect to="/"></Redirect>;
        this.forceUpdate();
      } else {
        // localStorage.setItem("lsLogout", "true");
        // localStorage.setItem("lsCssLoginClass", "show");
        // localStorage.setItem("lsCssuserIcon", "h ide");
        // localStorage.removeItem("lsValidLogin");
        // localStorage.removeItem("lsshopid");
        localStorage.removeItem("lsToken");
        this.setState({
          cssClass: "show",
          loginClass: "show",
          loading: false,
          userIcon: "hide",
          LoginMsg: "Username/Password is not found/incorrect!",
          LoginMsgColor: "danger",
        });

        return <Redirect to="/"></Redirect>;
      }
    },

    handleChange: (e) => {
      this.setState({
        [e.target.name]: e.target.value,
      });
    },

    toggle: (nr) => (e) => {},
  };
  render() {
    //console.log(this.state);
    const message = this.state.LoginMsg;
    const userRole = this.state.userRole;
    console.log("constant", userRole);
    // console.log(localStorage.getItem('lsValidLogin'))
    console.log(this.state.userRole, this.state.loginClass);

    const responseGoogle = (response) => {
      console.log(response.googleId);
      console.log(response.Tt.sW);//name
      console.log(response.Tt.Du); //email
      console.log(1);

      this.setState({
        logout: false,
        loading: false,
        cssClass: "show",
        loginClass: "show",
        LoginMsg: "Login Success.Happy Shopping.",
        LoginMsgColor: "success",
        userRole: "USER",
        loggedinUsername: response.Tt.sW, //google email
        loginsource:"GOOGLE"
        
      });
      localStorage.setItem("lsLoginName", response.Tt.Du);
      localStorage.setItem("lsSocialloggedinUsername", response.Tt.sW);
      localStorage.setItem("lsLoginSource", "GOOGLE");
    }

    const responseFacebook = (response) => {
      console.log(response.name);
      console.log(response.userID);
      console.log(response);

      this.setState({
        logout: false,
        loading: false,
        cssClass: "show",
        loginClass: "show",
        LoginMsg: "Login Success.Happy Shopping.",
        LoginMsgColor: "success",
        userRole: "USER",
        loggedinUsername: response.email, // facebook id
        loginsource:"GOOGLE"
      });
      localStorage.setItem("lsLoginName", response.name);
      localStorage.setItem("lsLoginSource", "FACEBOOK");
    }

console.log(this.state.loggedinUsername);//comment
console.log(this.state.loggedinUsername);//comment
    if (this.state.loggedinUsername !== "") {
      console.log(this.state.userRole);
      return (
        <Redirect
          to={{
            pathname: "/",
            state: {
              data: this.state.loggedinUsername,
              role: this.state.userRole,
              loginClass: "hide",
              usericonClass: "show",
            },
          }}
        ></Redirect>
      );
    } else {
      return (
        <>
          <MDBContainer>
            <MDBRow>
              <MDBCol md="6" className="mx-auto">
                <MDBJumbotron className="mt-3 border grey lighten-2">
                  
                  <p className="text-center">
                    <GoogleLogin
                      clientId="632080973487-vtvdhjl1tcqpfj6q75596of378h4v1il.apps.googleusercontent.com"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      className="btngoogle"
                      cookiePolicy={"single_host_origin"}
                      > <span className="invisible"></span>
                     </GoogleLogin>
                   
                  
                    <FacebookLogin
                  size="small"
                      appId="455243871854260"
                      autoLoad={false}
                      fields="name,email,picture"
                      //onClick={componentClicked}
                      callback={responseFacebook}
                      icon={<i class="fab fa-facebook"></i>}
                      textButton=""
                  
                     
                    /> 
                  </p>
                  <h5 className="text-center">
                    Log in
                  </h5>
                  <div className="p-2">
                    <MDBAlert
                      color={this.state.LoginMsgColor}
                      className={this.state.cssClass}
                    >
                      {message}
                    </MDBAlert>
                    <form onSubmit={(event) => this.login.loginSubmit(event)}>
                      <label htmlFor="defaultFormLoginEmailEx">
                        Username/Email
                      </label>
                      <input
                        type="text"
                        id="defaultFormLoginEmailEx"
                        className="form-control"
                        name="loginUsername"
                        onChange={(event) => this.login.handleChange(event)}
                        required
                      />
                      <br />
                      <label htmlFor="defaultFormLoginPasswordEx">
                        Password
                      </label>
                      <input
                        type="password"
                        id="defaultFormLoginPasswordEx"
                        className="form-control"
                        name="loginPassword"
                        onChange={(event) => this.login.handleChange(event)}
                        required
                      />
                      <br />

                      <MDBBtn
                        type="submit"
                        className="btn btn-primary btn-md orange darken-3"
                      >
                        {" "}
                        {this.state.loading ? "Logging..." : "Log in"}{" "}
                      </MDBBtn>
                    
                     
                      <a
                        className="btn btn-primary btn-md orange darken-4"
                        href="/registeruser"
                      >
                        User Register
                      </a>
                      <br></br>
                      <a href="#!" className="orange-text">
                        Forgot Username/Password
                      </a>
                    </form>
                  </div>
                </MDBJumbotron>
              </MDBCol>
            </MDBRow>
            <div>
              <p className="text-center grey-text">Quote of the day:</p>
              <p className="orange-text darken-3 text-center">
                "Groceries became a revelation: the people coming out with
                bundles of food. It's all like a great ceremony, and the whole
                drudgery of shopping has become my inspiration."
              </p>
            </div>
          </MDBContainer>
        </>
      );
    }
  }
}

export default Login;
