import React from "react";

const Pagenumber = ({ postsPerPage, totalPosts, paginate }) => {
  // const [currentPage, setcurrentPage] =React.useState(0)

  const pageNumbers = [];
  const pageNumbersmax = [];
  let width = {
    align: "center"
  };
  let width50 = {
    width: "60px",
    align: "left"
  };
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  for (let i = 1; i <= 5; i++) {
    pageNumbersmax.push(i);
  }
  return (
    <div >
      <nav style={width}>
        <ul className="pagination">
          {pageNumbersmax.map(number => (
            <li id="productionpagenumner" key={number} className="page-item">
              <a
                onClick={() => paginate(number)}
                href="!#"
                className="page-link"
              >
                {number}
              </a>
            </li>
          ))}

          <select
            id="drppage"
            className="browser-default custom-select"
            style={width50}
            onChange={e => paginate(Number(e.target.value))}
            //onChange={(e) => { setcurrentPage(Number(e.target.value)) }}
          >
            {pageNumbers.map(number => (
              <option key={number}>{number}</option>
            ))}
            ;
          </select>
        </ul>
      </nav>
    </div>
  );
};

export default Pagenumber;
