import React, { useState, useEffect } from "react";

import {
  MDBEdgeHeader,
  MDBContainer,
  MDBRow,

  //MDBCardTitle,
  MDBAnimation,
  MDBBtn,
  //MDBNavLink
  //MDBBtn
} from "mdbreact";
import "../index.css";
import Sidebar from "react-sidebar";

import Posts from "./Posts";
import Pagenumber from "../components/Pagenumber";
import { fetchService } from "../utilities/GlobalFunctions";
//import AniDatatablePage from "./AniDatatablePage";
//import DatatablePage from "./DatatablePage";
//import RegisterShopNavPage from "./RegisterShopNavPage";

const ProductPage = (props) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(12);
  const [loggedinUsername, setLoggedinUsername] = useState(
    props.location.state.loggedinUsername
  );
  const [userRole, setUserRole] = useState(props.location.state.userRole);
  const [sidebarOpen, setsidebarOpen] = useState(false);
  const [cat, setCat] = useState([]);

  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true);
      const res = await fetchService("ManageProduct", "GetAllProducts", "POST");
      setPosts(res);
      setLoading(false);
      // console.log('from product',loggedinUsername,userRole);
    };
    fetchPost();
  }, []);

  const onSetSidebarOpen = (open) => {
    setsidebarOpen(open);
  };

  const productcaetogory=
    [{
      "name": "Alberta",
      "abbreviation": "AB"
    },
    {
      "name": "British Columbia",
      "abbreviation": "BC"
    },
    {
      "name": "Manitoba",
      "abbreviation": "MB"
    },
    {
      "name": "New Brunswick",
      "abbreviation": "NB"
    },
    {
      "name": "Newfoundland and Labrador",
      "abbreviation": "NL"
    }]
   //setCat(productcaetogory);
  
  console.log(1);
    
  

  const Onsubmitcategory = () => {
    console.log("submit");
    onSetSidebarOpen(false);
  };

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const style = {
    height: "auto",
  };

  return (
    <>
    
      <MDBEdgeHeader color="orange lighten-1" className="sectionPage" />
     <div className="sidebar">
      <Sidebar 
              sidebar={
                <div>
                  <h5>Select Caetogory</h5>

                  <div>
                    <hr></hr>
                    <div class="custom-control custom-checkbox">

                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="defaultChecked2"
                        checked
                      />
                      <label class="custom-control-label" for="defaultChecked2">
                        Electronics
                      </label>
                      <hr></hr>
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="defaultChecked2"
                        checked
                      />
                      <label class="custom-control-label" for="defaultChecked2">
                        Default 
                      </label>
                      <hr></hr>
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="defaultChecked2"
                        
                      />
                      <label class="custom-control-label" for="defaultChecked2">
                        Vegetable
                      </label>
                      <hr></hr>
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="defaultChecked2"
                      />
                      <label class="custom-control-label" for="defaultChecked2">
                        Default checked
                      </label>
                      <hr></hr>
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="defaultChecked2"
                        checked
                      />
                      <label class="custom-control-label" for="defaultChecked2">
                        Default checked
                      </label>
                      <hr></hr>
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="defaultChecked2"
                      />
                      <label class="custom-control-label" for="defaultChecked2">
                        Default checked
                      </label>
                      <hr></hr>
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="defaultChecked2"
                        checked
                      />
                      <label class="custom-control-label" for="defaultChecked2">
                        Default checked
                      </label>
                      <hr></hr>
                    </div>
                  </div>
                  <MDBBtn className="btn-sm grey" onClick={() => Onsubmitcategory()}>Filter
                     <i class="fas fa-filter" onClick={() => Onsubmitcategory()}>{"    "}</i>
                  </MDBBtn>
                </div>
              }
              open={sidebarOpen}
              onSetOpen={onSetSidebarOpen}
              docked={false}
            
              styles={{
                sidebar: {
            
                  padding: "10px",
                  height: "auto",
                  background: "orange",
                  width: "300px",
                },
              }}
            > 
              <a
                className="filterbuttonclass"
                onClick={() => onSetSidebarOpen(true)}
              >
                <i class="fas fa-filter" onClick={() => onSetSidebarOpen(true)}></i>
              </a>
            </Sidebar>

            </div>
      <div className=" mb-1 minheight450">
        <MDBAnimation type="zoomIn" duration="500ms">
        <MDBContainer style={style}>
           <Posts
              posts={currentPosts}
              loading={loading}
              loggedinUsername={loggedinUsername}
              userRole={userRole}
            />

            <div className="mytextcenter mt-4 ">
              <MDBRow />
              <Pagenumber
                postsPerPage={postsPerPage}
                totalPosts={posts.length}
                paginate={paginate}
              />
            </div>
          </MDBContainer>
        </MDBAnimation>
      </div>
    </>
  );
};

export default ProductPage;
