import React ,  { Component }from "react";
import {
  MDBRow,
  MDBCol,
  MDBView,
  MDBCardBody,
  MDBMask,
} from "mdbreact";

import "../index.css";

class Homesection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseID: "",
      ModalLogin: false,
      Modal15: false,
      Modal16: false}
    }
  
  render() {
  
  return (
    <>

            <section className="text-center">
              <MDBRow className="text-center p-3">
                <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
                  <MDBView className="overlay rounded z-depth-1" waves>
                    <img
                      src="https://mdbootstrap.com/img/Photos/Others/images/58.jpg"
                      alt=""
                      className="img-fluid"
                    />
                    <a href="#!">
                      <MDBMask overlay="white-slight" />
                    </a>
                  </MDBView>
                  <MDBCardBody className="pb-0">
                    <h5 className="font-weight-bold my-3">Shop All Product</h5>
                    <p className="grey-text">
                      Buy product from your door steps
                    </p>
                 
                  </MDBCardBody>
                </MDBCol>
                <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
                  <MDBView className="overlay rounded z-depth-1" waves>
                    <img
                      src="https://mdbootstrap.com/img/Photos/Others/project4.jpg"
                      alt=""
                      className="img-fluid"
                    />
                    <a href="#!">
                      <MDBMask overlay="white-slight" />
                    </a>
                  </MDBView>
                  <MDBCardBody className="pb-0">
                    <h5 className="font-weight-bold my-3">Register your Shop</h5>
                    <p className="grey-text">
                     Start Selling your Product
                    </p>
                    
                  </MDBCardBody>
                </MDBCol>
                <MDBCol lg="4" md="12" className="mb-lg-0 mb-4">
                  <MDBView className="overlay rounded z-depth-1" waves>
                    <img
                      src="https://mdbootstrap.com/img/Photos/Others/images/88.jpg"
                      alt=""
                      className="img-fluid"
                    />
                    <a href="#!">
                      <MDBMask overlay="white-slight" />
                    </a>
                  </MDBView>
                  <MDBCardBody className="pb-0">
                    <h5 className="font-weight-bold my-3">About <span className="orange-text darken3">eShope</span></h5>
                    <p className="grey-text">
                     See how you can be benifited
                    </p>
                 
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </section>{" "}
       
    </>
  );
};}

export default Homesection;
