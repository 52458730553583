import React, { Component } from "react";

// import { MDBBtn, MDBTable, MDBTableBody, MDBTableHead  } from 'mdbreact';
import { MDBDataTable } from "mdbreact";
import "../index.css";
import Spinner, { fetchService } from '../utilities/GlobalFunctions';

class SearchShop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getvalue: [],
      getShopDetails: [],
      getSelectedValue: null,
      isLoading: true
    };
  }
  
  handleChange = (e) => {
    this.setState({ getSelectedValue: e.target.value });
  }

  async componentDidMount() {
    this.setState({
      getvalue: await fetchService('ShopLocation', 'GetLocation', 'Get'),
      getShopDetails: await fetchService('ShopLocation', 'GetShopDetails', 'POST'),
      isLoading: false
    });
  }

  render() {
    const griddata = this.state.getShopDetails;
    const data = {
      columns: [
        {
          label: 'ShopName',
          field: 'shopName',
          sort: 'asc',
          width: 150
        },
        {
          label: 'ShopAddress',
          field: 'shopAddress',
          sort: 'asc',
          width: 270
        },
        {
          label: 'City',
          field: 'city',
          sort: 'asc',
          width: 200
        },

      ],
      //rows:this.tableData
      rows: this.state.isLoading===false && griddata.map(obj=> ({"ShopName":obj.shopName,"ShopAddress":obj.shopAddress
         ,"City":obj.city}))
      
    };
console.log(this.tableData)
    return (
      <>
        {this.state.isLoading && <Spinner> Loading ...</Spinner>}
        {!this.state.isLoading && this.state.getvalue &&
          <>
            <label htmlFor="defaultFormLoginEmailEx">Shop Location</label>
            <select id="defaultFormContactSubjectEx1" className="browser-default custom-select form-control" onChange={this.handleChange}>
              {this.state.getvalue.map(obj => <option key={obj.locationValue}>{obj.locationName}</option>)};
        </select>
          </>
        }
        {this.state.isLoading && <Spinner> Loading ...</Spinner>}
        {!this.state.isLoading && this.state.getShopDetails &&
          <>
            <MDBDataTable id="searchshop" striped bordered  data={data} searching={true} >
              {/* <MDBTableHead columns={columns} /> */}
              {/* <MDBTableBody rows={rows_regular_btn} /> */}
            </MDBDataTable>
          </>
        }

      </>
    );
  }
}

export default SearchShop;
