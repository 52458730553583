import React, { Component } from "react";
import { MDBDataTable,MDBRow,MDBCol,MDBJumbotron ,MDBContainer } from "mdbreact";
import { fetchService } from '../utilities/GlobalFunctions';
//import OrderList from "./OrderDetailList";
import { Link } from "react-router-dom";

class  CustomerOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LoadCustomerOrders: [],
      isLoading: true,
      modal: false,
      orderid:0,
      shopID: this.props.shopID
    };
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  async componentDidMount() {
   // const output = await fetchService('OrderDetails', 'GetOrderDetailsForOwner', 'POST', localStorage.getItem('lsLoginName'));
    const output = await fetchService('OrderDetails', 'GetOrderDetailstoOwner', 'POST', localStorage.getItem('lsLoginName')+','+ this.state.shopID);
    if (output !== null) {
      this.setState({
        isLoading: false,
        LoadCustomerOrders: output
      });
    }
    console.log(this.state.LoadCustomerOrders);
    console.log(localStorage.getItem('lsLoginName')+','+this.state.shopID)
  }
 
render() {
  const griddata = this.state.LoadCustomerOrders;
  const data = {
    columns: [
      {
        label: "OrderId",
        field: "customerOrderid",
        sort: "asc"
      },
      {
        label: "Name",
        field: "firstname",
        sort: "asc"
      },
      {
        label: "Address",
        field: "useraddress",
        sort: "asc"
      },     
      
      {
        label: "City",
        field: "usercity",
        sort: "asc"    
      },
      {
        label: "Order Status",
        field: "statusvalue",
        sort: "asc"
      },
      
    ],
    // rows: this.state.isLoading === false && griddata.map(obj => ({
    //   // 'handle':<MDBBtn className="btn-sm orange darken-3 white-text" >
    //   //  <MDBIcon icon="angle-double-right" /></MDBBtn>,
    //   "customerName": obj.lobjUserRegistration.fullName,
    //   "customerMobile": obj.lobjUserRegistration.userMobileNo
    //   ,"customerAddress": obj.lobjUserRegistration.userAddress
    //   , "numberofItems": obj.numberofItems
    //   //, "price": obj.price
    //   ,"totalPrice": obj.totalPrice
    //   ,"orderDate":obj.createDate
    //   //,"stockinStore": obj.StockInStore,
    //   //"remove": ''

    rows: this.state.isLoading === false && griddata.map(obj => ({

      "customerOrderid":   <Link className="btn-sm orange darken-3 white-text" to={{ pathname: '/Orderlists', state: { orderid: obj.customerOrderid, shopID: this.state.shopID} }}> {obj.customerOrderid}</Link> ,
      "firstname": obj.firstname,
      "useraddress": obj.useraddress,
       "usercity": obj.usercity,
      "statusvalue": obj.statusvalue
    }))
  };

  return(
 <>
   <div>
    <MDBContainer>
    <MDBRow>
      <MDBCol className="mx-auto">
        <MDBJumbotron id="customerorder" className="mt-3 p-5">
          <div className="overflow-x">
          <MDBDataTable id="tblcustomerorder" striped bordered small data={data} entries="10"  searching={true}/>
          </div>
        </MDBJumbotron>
      </MDBCol>
    </MDBRow>
        </MDBContainer>
    </div>
    </>
);
  };
};

export default CustomerOrders;
