import React from "react";
import {
  MDBEdgeHeader,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBJumbotron,
  MDBIcon,
  MDBAnimation,
  MDBView, 
  MDBCardBody,
  MDBCard
} from "mdbreact";
//import MenuLink from "./../components/menuLink";

const AssociatesPage = () => {
  return (
    <>
    <MDBEdgeHeader color="orange lighten-1" className="sectionPage" />
      <MDBAnimation type="zoomIn" duration="500ms">
        <MDBContainer>
          <MDBRow>
            <MDBCol md="10" className="mx-auto">
                <MDBJumbotron className="mt-3">
                  <h2 className="text-center">
                    <span  className='orange-text small' ><MDBIcon icon="hands-helping" /></span>
                     Key Associates
                  </h2>
                  <MDBCard className="my-5 px-5 pb-5 text-center mycard">
        <MDBCardBody>
          <MDBRow>
            <MDBCol lg="6" md="6" >
              <MDBView>
                <img 
                tag="img"
                href="#a"
                src="https://testbucket101289.s3.amazonaws.com/Male-businessman-310819_640.png-e6a91a1f-1645-41ff-9c37-4cb8ffb5b038.png"
                className="rounded-circle z-depth-1 img-fluid"
                alt="Sample avatar"
              />
              </MDBView>
              <h5 className="font-weight-bold mt-4 mb-3 orange-text-dark3">Balakumar Velusamy</h5>
              <p className="text-uppercase blue-text grey-text">Founder</p>
              <p className="grey-text">
               vbalakumar.cse@gmail.com </p>
               <p className="grey-text">
               About:
               Balakumar Velusamy, graduated from Tamil Nadu, India and holds a degree in Computer Science and Engineering. 
               Passionate about art, webdesigning, researching and implementing new technologies. 
               Looking for more opportunities to  enhance his creative skills he stumbled in creating this portal. 
               What started as a pass-time activity became his passion.
               </p>
               <br/>
               <br/>
               
              <ul className="list-unstyled mb-0 ">
                <a href="#!" className="p-2 fa-lg">
                  <MDBIcon fab icon="facebook-f" className="orange-text-dark3" />
                </a>
                <a href="#!" className="p-2 fa-lg">
                  <MDBIcon fab icon="twitter" className="orange-text-dark3" />
                </a>
                <a href="www.instagram.com/theuniquecreations" className="p-2 fa-lg">
                  <MDBIcon fab icon="instagram" className="orange-text-dark3"/>
                </a>
              </ul>
              <br/>
              
            </MDBCol>
            <MDBCol lg="6" md="6" >
              <MDBView>
                <img 
                tag="img"
                href="#a"
                src="https://testbucket101289.s3.amazonaws.com/Female-teacher-295387_640.png-6f982cd8-66fb-46eb-b8c1-a60b4b878fa2.png"
                className="rounded-circle z-depth-1 img-fluid"
                alt="Sample avatar"
              />
              </MDBView>
              <h5 className="font-weight-bold mt-4 mb-3 orange-text-dark3">Sathya Gopal</h5>
              <p className="text-uppercase blue-text grey-text">Co-Founder</p>
              <p className="grey-text">
               satsri46@gmail.com </p>
              <ul className="list-unstyled mb-0 ">
                <a href="#!" className="p-2 fa-lg">
                  <MDBIcon fab icon="facebook-f" className="orange-text-dark3" />
                </a>
                <a href="#!" className="p-2 fa-lg">
                  <MDBIcon fab icon="twitter" className="orange-text-dark3" />
                </a>
                <a href="#!" className="p-2 fa-lg">
                  <MDBIcon fab icon="instagram" className="orange-text-dark3"/>
                </a>
              </ul>
            </MDBCol>
          
       </MDBRow>
        </MDBCardBody>
      </MDBCard>
  
                   </MDBJumbotron>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
      </MDBAnimation>
    </>
  );
};

export default AssociatesPage;



