import React, { Component } from "react";
import {
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBEdgeHeader,
  MDBFreeBird,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBCardBody
} from "mdbreact";

import CartTable from "./CartTable";
import CheckOut from "./CheckOut";
import OrderConfirmation from "./OrderConfirmation";
import { fetchService } from '../utilities/GlobalFunctions';

import "../index.css";
import { Redirect } from "react-router-dom";

class CartCheckOutOrder extends Component {
  state = {
    activeItem: "1",
    cssClass: "activetab",
    getdropdowndetails: [],
    isLoading: true,
    CodeID: '',
    CodeValue: '',
    CodeDescription: '',
    Data1: '',
    CreatedBy: '',
    UpdatedBy: '',
    cssSuccessMessage: 'hide'
  };

  Adminproduct = {

    addProduct: async () => {
      const data = {
        CodeID: this.state.CodeID,
        CodeValue: this.state.CodeValue,
        CodeDescription: this.state.CodeDescription,
        Data1: this.state.Data1,
        CreatedBy: localStorage.getItem('lsLoginName'),
        UpdatedBy: localStorage.getItem('lsLoginName')
      }
      const output_save = await fetchService('CodeValue', 'SaveData', 'POST', data);
      if (output_save !== "E") {
        this.setState({ cssSuccessMessage: 'show' });
      }
      console.log(output_save);
    }
  }
  toggle = tab => e => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
        cssClass: "activetab"
      });
    }
  };
  async componentDidMount() {
    this.setState({
      getdropdowndetails: await fetchService('ShopLocation', 'GetCodeID', 'Get'),
      isLoading: false

    });
    console.log(this.state.getdropdowndetails)
  }

  render() {
    // const activetab = "activetab";
    // const notactivetab = "notactivetab";
    if (localStorage.getItem('lsLoginName')!== null && ((localStorage.getItem('lsRole') === 'USER') || (localStorage.getItem('lsRole') === 'OWNER'))) {
      return (
        <>
          <MDBEdgeHeader color="orange lighten-1" className="sectionPage" />
          <div className="mt-3 mb-5" >
            <MDBFreeBird>
              <MDBRow className="orderpageheight">
                <MDBCol
                  md="12"
                  className="mx-auto float-none white z-depth-1 py-2 px-2"
                >
                  <MDBCardBody className="text-center">
                    {/* <p className="mb-3 orange-text-dark3">
                      <strong>Welcome {localStorage.getItem('lsUserName')}</strong>
                    </p> */}
                    <hr className="my-1" />
                    <MDBNav className="nav-tabs mt-2">
                      <MDBNavItem>
                        <MDBNavLink
                          to="#"
                          active={this.state.activeItem === "1"}
                          onClick={this.toggle("1")}
                          role="tab"
                          className={
                            this.state.activeItem === "1" && this.state.cssClass
                          }
                        >
                          View Cart
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem>
                        <MDBNavLink
                          to="#"
                          active={this.state.activeItem === "2"}
                          onClick={this.toggle("2")}
                          role="tab"
                          className={
                            this.state.activeItem === "2" && this.state.cssClass
                          }
                        >
                          Checkout
                        </MDBNavLink>
                      </MDBNavItem>

                      <MDBNavItem>
                        <MDBNavLink
                          to="#"
                          active={this.state.activeItem === "3"}
                          onClick={this.toggle("3")}
                          role="tab"
                          className={
                            this.state.activeItem === "3" && this.state.cssClass
                          }
                        >
                          Order
                        </MDBNavLink>
                      </MDBNavItem>
                    </MDBNav>
                    <MDBTabContent className="minheight350" activeItem={this.state.activeItem}>
                      <MDBTabPane tabId="1" role="tabpanel">

                        <CartTable />
                      </MDBTabPane>
                      <MDBTabPane tabId="2" role="tabpanel">
                        <CheckOut />
                      </MDBTabPane>
                      <MDBTabPane tabId="3" role="tabpanel">
                        <OrderConfirmation />
                      </MDBTabPane>
                    </MDBTabContent>

                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBFreeBird>

            <MDBContainer>
              <MDBRow>
                <MDBCol md="12" className="mt-4">
                  <p className="text-center text-muted mb-1">
                    View Cart > Checkout > Order Confirmation
                  </p>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
        </>
      );
    }
    else {
      return <Redirect to="/login"></Redirect>
    }
  }
}
export default CartCheckOutOrder;
