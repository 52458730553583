import React, { Component } from "react";
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBEdgeHeader,
  MDBJumbotron,
  MDBNavLink,
  MDBAlert
} from "mdbreact";
import { fetchService } from "../utilities/GlobalFunctions";
//import MenuLink from "./../components/menuLink";
import Autosuggest from "react-autosuggest";
import "../index.css";

class ManageProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      suggestions: [],
      getImageDetails: [],
      getProductCategory: [],
      getProductSubCategory: [],
      ProductImageID: 0,
      ProductName: "",
      ProductDescription: "",
      ProductCategoryValue: "",
      ProductSubCategoryValue: "",
      Quantity: 0,
      Price: 0,
      ProductID:0,
      cssClass: "hide",
      viewImage:'',
      userRole: (this.props.location.state && this.props.location.state.data !== undefined) ? this.props.location.state.data:"",
      shopID: (this.props.location.state && this.props.location.state.shopID !== undefined) ?this.props.location.state.shopID: 0
    };
    this.ManageProduct.postData = this.ManageProduct.postData.bind(this);
    this.ManageProduct.handleChange = this.ManageProduct.handleChange.bind(
      this
    );
  }
  async componentDidMount() {
    this.setState({
      getProductCategory: await fetchService(
        "ShopLocation",
        "GetCodeValue",
        "POST",
        "ProductCategory"
      )
    });
    this.setState({
      getProductSubCategory: await fetchService(
        "ShopLocation",
        "GetCodeValue",
        "POST",
        "ProductSubCategory"
      )
    });
    this.setState({
      getImageDetails: await fetchService(
        "Image",
        "GetImageDetails",
        "POST",
       this.state.userRole
      )
    });
    console.log(this.state.getImageDetails);
    if(this.props.location.state.mode === "new"){
      console.log('it is new mode');
    }
    else {
      console.log(this.props.location.state.mode);
      this.setState({
        ProductName: this.props.location.state.mode.productName,
        ProductImageID : this.props.location.state.mode.productImageID,
        ProductCategoryValue : this.props.location.state.mode.productCategoryValue,
        ProductSubCategoryValue : this.props.location.state.mode.productSubCategoryValue,
        Price : this.props.location.state.mode.price,
        Quantity: this.props.location.state.mode.quantity,
        ProductID : this.props.location.state.mode.productID,
        ProductDescription: this.props.location.state.mode.productDescription,
        viewImage: this.props.location.state.mode.imageUrl
      });
      
    }
  }
  ManageProduct = {
    postData: async (event) => {
      event.preventDefault();
      // postData = async () => {
      this.setState({
        isLoading: true
      });
      const data = {
        // user
        ProductID : parseInt(this.state.ProductID),
        ProductName: this.state.ProductName,
        //ProductCategoryID: this.state.userFirstName,
        ProductCategoryValue: this.state.ProductCategoryValue,
        //ProductSubCategoryID: this.state.userLastName,
        ProductSubCategoryValue: this.state.ProductSubCategoryValue,
        ProductDescription : this.state.ProductDescription,
        Quantity:  parseInt(this.state.Quantity),
        ProductImageID: parseInt(this.state.ProductImageID),
        Price: parseFloat(this.state.Price),
        CreatedBy: localStorage.getItem("lsLoginName"),
        UpdatedBy: localStorage.getItem("lsLoginName")
      };

      const output_save = await fetchService(
        "ManageProduct",
        "SaveProductDetails",
        "POST",
        data
      );

      if (output_save ===200) {
        this.setState({
          isLoading: false,
          cssClass: "show",
          ErrorMessage:
            "Product added/Updated succesfully",
          ShopRegMsgColor: "success"
        });
        setTimeout(() => window.location.reload(), 5000);
      } else {
        this.setState({
          isLoading: false,
          cssClass: "show",
          ErrorMessage: "Please enter correct details and register",
          ShopRegMsgColor: "danger"
        });
        //setTimeout(() => window.location.reload(), 1000);
      }

      console.log(output_save);
    },
    handleChange: e => {
      this.setState({
        [e.target.name]: e.target.value
      });
      console.log(this.state[e.target.name]);
    }
  };
  AutoComplete = {
    getSuggestions: value => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      return inputLength === 0 || this.state.getImageDetails.length === 0
        ? []
        : this.state.getImageDetails
            .map(m => m)
            .filter(
              lang => lang.tags.toLowerCase().includes(inputValue) // === inputValue
            );
    },
    getSuggestionValue: suggestion => suggestion.imagename,

    onChange: (event, { newValue }) => {
      this.setState({
        value: newValue
      });
    },
    onSuggestionsFetchRequested: ({ value }) => {
      this.setState({
        suggestions: this.AutoComplete.getSuggestions(value)
      });
      this.setState({
        ProductImageID: this.state.suggestions.map(m => m.imageID).toString(),
        viewImage: this.state.suggestions.map(m=> m.imageUrl)
      });
      console.log(this.state.viewImage);
    },

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested: () => {
      this.setState({
        suggestions: []
      });
    }
  };

  render() {
    // localStorage.setItem("lsRedirect","Product");
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: "Search Image",
      value,
      onChange: this.AutoComplete.onChange
    };
    // Use your imagination to render suggestions.
    const renderSuggestion = suggestion => (
      <div className="auto-suggestion-div">
        <img
          src={suggestion.imageUrl}
          alt="NoImage"
          className="search-image-drp-style"
        />
        {suggestion.imagename}
      </div>
    );
    return (
      <>
        <MDBEdgeHeader color="orange lighten-1" className="sectionPage" />

        <MDBContainer>
          <MDBRow>
            <MDBCol md="12" className="mx-auto">
              <MDBJumbotron className="mt-3">
                <MDBNavLink
                  className="close px-3 orange-text "
                  aria-label="Close"
                  to="./ShopOwnerOrder"
                >
                  <span>&times;</span>
                </MDBNavLink>
                <h3 className="text-center"> {this.props.location.state.mode === "new"?"Add Product":"Update Product"}</h3>
                <MDBAlert
                      color={this.state.ShopRegMsgColor}
                      className={this.state.cssClass}
                    >
                      {this.state.ErrorMessage}
                    </MDBAlert>
                <MDBRow className="p-3">
                  <MDBCol className="mx-auto text-left">
                    <form
                      id="shopregister"
                      onSubmit={event => this.ManageProduct.postData(event)}
                    >
                      <div className="form-group set-width-image">
                        <label htmlFor="inputpimage">Search Image</label>
                        <Autosuggest
                          suggestions={suggestions}
                          onSuggestionsFetchRequested={
                            this.AutoComplete.onSuggestionsFetchRequested
                          }
                          onSuggestionsClearRequested={
                            this.AutoComplete.onSuggestionsClearRequested
                          }
                          getSuggestionValue={
                            this.AutoComplete.getSuggestionValue
                          }
                          renderSuggestion={renderSuggestion}
                          inputProps={inputProps}
                          type="text"
                          className="form-control"
                          id="inputpimage"
                          value ={this.state.ProductImageID}
                        />
                      </div>
                      <div className="set-width-image padding-left">
                      <img src={this.state.viewImage} alt="EshopE" className="search-image-drp-style"/>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputpname">
                          Product Name <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="ProductName"
                          placeholder="Product Name"
                          onChange={event =>
                            this.ManageProduct.handleChange(event)
                          }
                          required
                          value={this.state.ProductName}
                        />
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="inputcat">
                            Product Category <span className="required">*</span>
                          </label>
                          <select
                            type="text"
                            className="form-control"
                            name="ProductCategoryValue"
                            placeholder="State"
                            onChange={event =>
                              this.ManageProduct.handleChange(event)
                            }
                            required
                            value={this.state.ProductCategoryValue}
                          >
                            <option value="" key="-1">--select--</option>
                            {!this.state.isLoading &&
                              this.state.getProductCategory != null &&
                              this.state.getProductCategory.map(details => (
                                <option value={details.codeValue} key={details.codeDescription}>
                                  {details.codeDescription}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="inputsubcat">
                            Prodcut Sub Category{" "}
                            <span className="required">*</span>
                          </label>
                          <select
                            type="text"
                            className="form-control"
                            name="ProductSubCategoryValue"
                            placeholder="State"
                            onChange={event =>
                              this.ManageProduct.handleChange(event)
                            }
                            required
                            value ={this.state.ProductSubCategoryValue}
                          >
                            <option value="" key="-1">--select--</option>
                            {!this.state.isLoading &&
                              this.state.getProductSubCategory != null &&
                              this.state.getProductSubCategory.map(details => (
                                <option value={details.codeValue} key={details.codeDescription}>
                                  {details.codeDescription}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="inputdescription">
                          Product Description
                        </label>
                        <textarea
                          type="multi "
                          className="form-control"
                          name="ProductDescription"
                          placeholder="Describe about your Product"
                          onChange={event =>
                            this.ManageProduct.handleChange(event)
                          }
                          value= {this.state.ProductDescription}
                        />
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="inputquantity">
                            Quantity <span className="required">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="Quantity"
                            placeholder="Enter available quantity"
                            onChange={event =>
                              this.ManageProduct.handleChange(event)
                            }
                            required
                            value={this.state.Quantity}
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="inputprice">
                            Price <span className="required">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            name="Price"
                            placeholder="Enter Price per Item"
                            onChange={event =>
                              this.ManageProduct.handleChange(event)
                            }
                            required
                            value ={this.state.Price}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="inputprice">Discount Condition</label>
                          <select
                            type="text"
                            className="form-control"
                            name="Discount"
                            placeholder="State"
                            onChange={event =>
                              this.ManageProduct.handleChange(event)
                            }
                          >
                            <option value="1">Select</option>
                            <option value="2">Discount by Item %</option>
                            <option value="2">Discount by Price %</option>
                            <option value="2">Discount by Count %</option>
                            <option value="2">No Discount</option>
                          </select>
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="inputquantity">Discount %</label>
                          <input
                            type="number"
                            className="form-control"
                            name="Percentage"
                            placeholder="Enter available quantity"
                            defaultValue="0"
                            onChange={event =>
                              this.ManageProduct.handleChange(event)
                            }
                          />
                        </div>
                      </div>
                      <MDBBtn
                        type="submit"
                        className="btn btn-primary btn-md orange darken-3"
                      >
                         {this.props.location.state.mode === "new"?"Add Product":"Update Product"}
                       
                      </MDBBtn>
                      <MDBBtn className="btn btn-primary btn-md grey darken-3">
                        Clear
                      </MDBBtn>
                    </form>
                  </MDBCol>
                </MDBRow>
              </MDBJumbotron>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </>
    );
  }
}

export default ManageProduct;
