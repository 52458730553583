import React from "react";
//import axios from "axios";
//  import $ from "jquery";
import {
  MDBEdgeHeader,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBJumbotron,
  MDBIcon,
  MDBAnimation
} from "mdbreact";
//import MenuLink from "./../components/menuLink";

class ServicePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      selectedFiles: null
    };
  }
  singleFileChangedHandler = ( event ) => {
    console.log(event.target.files)
    this.setState({
     selectedFile: event.target.files[0]
    });
   };


  render() {
    console.log(this.state)
    return (
      <>
        <MDBEdgeHeader color="orange lighten-1" className="sectionPage" />
        <MDBAnimation type="zoomIn" duration="500ms">
          <MDBContainer>
            <MDBRow>
              <MDBCol md="10" className="mx-auto">
                <MDBJumbotron className="mt-3">
                  <h2 className="text-center">
                    <span className="orange-text small">
                      <MDBIcon icon="hands-helping" />
                    </span>
                    Our Service
                  </h2>

                  <p className="p-2">
                    This application shows the actual use of MDB React
                    components in the application.
                  </p>
                  <p className="p-2">
                    This application shows the actual use of MDB React
                    components in the application. This application shows the
                    actual use of MDB React components in the application. This
                    application shows the actual use of MDB React components in
                    the application. This application shows the actual use of
                    MDB React components in the application. This application
                    shows the actual use of MDB React components in the
                    application.
                  </p>
                  <p className="p-2">
                    This application shows the actual use of MDB React
                    components in the application. This application shows the
                    actual use of MDB React components in the application. This
                    application shows the actual use of MDB React components in
                    the application. This application shows the actual use of
                    MDB React components in the application. This application
                    shows the actual use of MDB React components in the
                    application.
                  </p>
                  <p className="p-2">
                    This application shows the actual use of MDB React
                    components in the application. This application shows the
                    actual use of MDB React components in the application.This
                    application shows the actual use of MDB React components in
                    the application.This application shows the actual use of MDB
                    React components in the application.This application shows
                    the actual use of MDB React components in the
                    application.This application shows the actual use of MDB
                    React components in the application.
                  </p>
                 
    
                </MDBJumbotron>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBAnimation>
      </>
    );
  }
}

export default ServicePage;
