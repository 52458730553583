import React from "react";
import FileBase64 from "react-file-base64";
import { fetchService } from "../utilities/GlobalFunctions";
import "../index.css";
import {
  MDBEdgeHeader,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBJumbotron,
  MDBIcon,
  MDBAlert
} from "mdbreact";
//import MenuLink from "./../components/menuLink";
import { Redirect } from "react-router-dom";
class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      picture: [],
      productname: "",
      imagetags: "",
      imagesize: "",
      imagetype: "",
      imagebase64: "",
      error: "",
      loading: false,
      cssClass: "hide",
      Msg: "",
      MsgColor: "",
      searchimage: "1",
      source:"Product",
      UpdatedBy:   localStorage.getItem("lsLoginName"),
      IsValidAdmin: (this.props.location.state && this.props.location.state.data !== undefined) ?this.props.location.state.data:""
    };
    this.uploadImages.uploadImages = this.uploadImages.uploadImages.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  getFiles(picture) {
    this.setState({ picture: picture });
    this.setState({ imagesize: picture.size });
    this.setState({ imagetype: picture.type });
    this.setState({ imagebase64: picture.base64 });
  }

 
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });

    e.preventDefault();
  };

  uploadImages = {
    uploadImages: async event => {
      event.preventDefault();
    const data = {
      productname: this.state.productname,
      imagename: this.state.picture.name,
      Tags: this.state.imagetags,
      imagetype: this.state.imagetype,
      imagebase64: this.state.imagebase64,
      imagesize: this.state.imagesize,
      isSearchable: this.state.searchimage,
      source:"Product",
      UpdatedBy: this.state.UpdatedBy
    };
    console.log(data);
    this.setState({ loading: true });
    const output_save = await fetchService(
      "Image",
      "LoadSingleImagetoS3",
      "POST",
      data
    );
    if (output_save ===200) {
      this.setState({
        loading: false,
        cssClass: "show",
        Msg: "Image Uploaded Sucessfully.",
        MsgColor: "success"
      });
      setTimeout(() => window.location.reload(), 5000);
    } else {
      this.setState({
        loading: false,
        cssClass: "show",
        userIcon: "hide",
        Msg: "Error in uploading the file. Please try again.",
        MsgColor: "danger"
      });
    }}
  };

  render() {
  console.log(this.data);
    var imagestyle = {
      width: 150,
      height: 150
    };
    const message = this.state.Msg;
    if (
      localStorage.getItem("lsLoginName")!=null &&
      this.state.IsValidAdmin!==""
    ) {
      return (
        <>
          <MDBEdgeHeader color="orange lighten-1" className="sectionPage" />

          <MDBContainer>
            <MDBRow>
              <MDBCol md="10" className="mx-auto">
                <MDBJumbotron className="mt-3">
                  <h3 className="text-center">
                    <span className="orange-text small">
                      <MDBIcon icon="hands-helping" />
                    </span>
                    Upload Images
                  </h3>

                  <p className="p-3">
                    Please upload the product images here. This image can be
                    searchable to all shop owners and they can use for their
                    products.
                  </p>

                  <MDBAlert
                    color={this.state.MsgColor}
                    className={this.state.cssClass}
                  >
                    {message}
                  </MDBAlert>

                  <div className="p-3">
                  <form onSubmit={event => this.uploadImages.uploadImages(event)}>
             <div className="form-row">
                        <div className="form-group col-md-12">
                          <label htmlFor="inputphoneno">
                            Product Image Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputimagename"
                            placeholder="Product Image Name"
                            name="productname"
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group col-md-12">
                          <label htmlFor="inputphoneno">Image Tags</label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputtags"
                            placeholder="Enter the tags seperated by comma"
                            name="imagetags"
                            onChange={this.handleChange}
                            required
                          />
                        </div>
                        <div className="form-group col-md-12">
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              class="custom-control-input"
                              id="defaultUnchecked"
                              name="searchimage"
                              value="1"
                              
                              onChange={this.handleChange}
                            />
                            <label
                              class="custom-control-label"
                              for="defaultUnchecked"
                            >
                              Searchable (Default)
                            </label>
                          </div>
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              class="custom-control-input"
                              value="0"
                              id="defaultChecked"
                              name="searchimage"
                              onChange={this.handleChange}
                            />
                            <label
                              class="custom-control-label"
                              for="defaultChecked"
                            >
                              Non Searchable
                            </label>
                          </div>{" "}
                        </div>
                        <div className="form-group col-md-12">
                          <label htmlFor="inputmobileno">
                            Upload Image(.jpg,.jpeg,.png, max 1 mb){" "}
                            <span className="required">*</span>
                          </label>
                          <br />
                          <FileBase64
                            id="picupload"
                            multiple={false}
                            className="orange darken-3"
                            onDone={this.getFiles.bind(this)}
                            required
                          />
                        </div>
                        <div className="form-group col-md-12">
                          <img
                            alt=""
                            src={this.state.picture.base64}
                            style={imagestyle}
                          />
                        </div>
                      </div>
                      <hr color="orange"></hr>

                      <button
                        type="submit"
                        className="btn btn-primary btn-md orange darken-3"
                        
                      >
                        {" "}
                        {this.state.loading
                          ? "Uploading..."
                          : "Upload Image"}{" "}
                      </button>
                   </form>
                  </div>
                </MDBJumbotron>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </>
      );
    } else {
      return <Redirect to="/"></Redirect>;
    }
  }
}

export default ImageUpload;
